import { ChangeDetectorRef, Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Helper } from 'app/common/helper';
import {
  APIEnum,
  BackgroundColorModeEnum,
  Constant,
  ErrorCodeNotificationMedia,
  ErrorEnum,
  FIELD_COMPONENT,
  FolderNameDropPDFEnum,
  MODULE_NAME,
  TypeMediaFileEnum
} from 'app/config/constants';
import { DialogConfirmComponent } from 'app/dialog/dialog-confirm/dialog-confirm.component';
import { DialogEmergencyModeComponent } from 'app/dialog/dialog-emergency-mode/dialog-emergency-mode.component';
import { DialogMessageComponent } from 'app/dialog/dialog-message/dialog-message.component';
import { DialogOperationInformationSettingScheduleComponent } from 'app/dialog/dialog-operation-information-setting-schedule/dialog-operation-information-setting-schedule.component';
import { DialogShowHiddenItemSettingSettingComponent } from 'app/dialog/dialog-show-hidden-item-setting/dialog-show-hidden-item-setting.component';
import { Common } from 'app/model/entity/common';
import { DynamicMessage } from 'app/model/entity/dynamic-message';
import { EmergencyData } from 'app/model/entity/emergency-data';
import { Image as ImageDynamicMessage } from 'app/model/entity/image';
import { Media } from 'app/model/entity/media';
import { DynamicMessageSchedule } from 'app/model/entity/schedule/dynamic-message-schedule';
import { TimetableDaily } from 'app/model/entity/schedule/timetables-daily';
import { APICustomerService } from 'app/service/api-customer.service';
import { CommonService } from 'app/service/common.service';
import { DataService } from 'app/service/data.service';
import { DeviceService } from 'app/service/device.service';
import { DialogService } from 'app/service/dialog.service';
import { DynamicMessageService } from 'app/service/dynamic-message.service';
import { EmergencyDataService } from 'app/service/emergency-data.service';
import { ExecutingService } from 'app/service/executing.service';
import { MediaService } from 'app/service/media.service';
import { MenuActionService } from 'app/service/menu-action.service';
import { ScheduleMergeService } from 'app/service/schedule-merge.service';
import { ScheduleOperationManagerService } from 'app/service/schedule-operation-maganer.service';
import _ from 'lodash';
import moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { Subject, Subscription, forkJoin, of } from 'rxjs';
import { v4 as uniqueId } from 'uuid';
import { MediaFileDropped } from '../lcd-layout-editor/lcd-layout-editor.component';
import { NotificationMediaValidator } from './../../model/entity/schedule-operation-manager/notification-registration/NotificationMediaValidator';

import { moveItemInArray } from '@angular/cdk/drag-drop';
import { Store } from '@ngrx/store';
import { DialogAreaItemSettingComponent } from 'app/dialog-area-item-setting/dialog-area-item-setting.component';
import { DialogAreaSettingComponent } from 'app/dialog-area-setting/dialog-area-setting.component';
import { DialogChangeTimelineSettingComponent } from 'app/dialog/dialog-change-timeline-setting/dialog-change-timeline-setting.component';
import { DialogMessageOperationComponent } from 'app/dialog/dialog-message-operation/dialog-message-operation.component';
import { DialogSimpleSignageMessageComponent } from 'app/dialog/dialog-simple-signage-message/dialog-simple-signage-message.component';
import { CommonTable } from 'app/model/entity/commonTable';
import { NoticeText } from 'app/model/entity/schedule-operation-manager/notification-registration/NoticeText';
import { NotificationMedia } from 'app/model/entity/schedule-operation-manager/notification-registration/NotificationMedia';
import { SequenceNotification } from 'app/model/entity/sequence-notification';
import { CommonTableService } from 'app/service/common-table.service';
import { CustomTagService } from 'app/service/custom-tag.service';
import { TextHighlightSettingService } from 'app/service/text-highlight-setting-service';
import { AppState } from 'app/store/app.state';
import { DatePickerDirective } from 'ng2-date-picker';
import { takeUntil } from 'rxjs/operators';
import { QuillComponent } from './quill/quill.component';
@Component({
  selector: 'schedule-operation-manager',
  templateUrl: './schedule-operation-manager.component.html',
  styleUrls: ['./schedule-operation-manager.component.scss']
})
export class ScheduleOperationManagerComponent implements OnInit {
  @ViewChild(QuillComponent) quillComponent: QuillComponent;
  @ViewChild('listText', { static: false }) listTextDisplay: ElementRef;
  @ViewChild('sequenceScroll', { static: false }) sequenceScroll: ElementRef;
  @ViewChild('listMedia', { static: false }) listMediaDisplay: ElementRef;
  readonly INPUT_IMPORT_ID = 'importedFileNotificationRegistration';
  /**
   * PATH_ANGLE_DOUBLE_RIGHT
   */
  public PATH_ANGLE_DOUBLE_RIGHT = Constant.PATH_ANGLE_DOUBLE_RIGHT;
  /**
   * Helper
   */
  public Helper = Helper;
  /**
   * true if enlarge preview
   */
  public isEnlargePreview: boolean = true;
  /**
   * schedule selected
   */
  public scheduleSelected: any;

  /**
   * schedule selected clone
   */
  public scheduleSelectedClone: any;

  /**
   * list display
   */
  public nameDisplayClone: string;
  suspension = '運休';
  /**
   * schedule edit
   */
  public scheduleEdit: any;

  /**
   * schedule edit array
   */
  public scheduleEditArray: any[] = [];

  /**
   * schedule insert array
   */
  public scheduleInsertArray: any[] = [];
  /**
   * number row insert
   */
  public numRowInsert: number = -1;

  /**
   * schedule delete array
   */
  public scheduleDeleteArray: any[] = [];
  /**
   * tenant name
   */
  private tenantName: any;
  /**
   * schedules daily
   */
  public schedulesDaily: any[] = [];
  /**
   * timetables daily
   */
  public timetablesDaily: TimetableDaily[] = new Array<TimetableDaily>();
  /**
   * label schedule
   */
  public labelSchedule: string;
  /**
   * subscriptions
   */
  private subscriptions: Array<Subscription> = new Array<Subscription>();
  /**
   * devices
   */
  public devices: DeviceOperation[];

  public deviceIdSelected: any;
  /**
   * true if edit row
   */
  public isEditRow: boolean;
  /**
   * device selected
   */
  public deviceSelected: DeviceOperation;

  dataLengthAPI;
  /**
   * size of data load from api
   */
  private size = 50;
  /**
   * index last of data schedulesDaily
   */
  private indexItem = 0;

  isChangedData = false;
  /**
   * isLoadHeader of data schedulesDaily
   */
  private isLoadHeader = false;
  /**
   * stopLazyScroll
   */
  public stopLazyScroll: Subject<any> = new Subject<any>();

  public ngUnsubscribe: Subject<void> = new Subject<void>();

  public isActiveTabCapture: boolean = true;
  // public deviceSelected: DeviceOperation;
  public listSelectedItem: any;

  public listSelectedItemName: any;

  public areaName: string;

  isRoot: boolean;

  @ViewChild('groupPlaylistText', { static: false }) groupPlaylistTextElement: ElementRef;
  @ViewChild('groupPlaylistMedia', { static: false }) groupPlaylistMediaElement: ElementRef;

  public groupPlaylistText = { id: '1', name: this.translateService.instant('schedule-operation-manager.text'), isExpand: false };

  public groupPlaylistMedia = { id: '2', name: this.translateService.instant('schedule-operation-manager.media'), isExpand: false };

  playlistGroupSelected: any;

  playlistTexts: SequenceNotification[] = [];

  playlistMedias: SequenceNotification[] = [];

  playlistSelected: SequenceNotification;

  oldValuePlaylistName: string;

  sequenceOrigin: any;

  sequenceOriginClone: any;

  isDraggingMedia: boolean;

  backgroundColor: string = 'light';
  sequenceDisplay: any = [];
  timelineDivision: any;
  timelineDivisionDisplay: any;
  countItem: any;

  TIMELINE_DIVISION_VALUE_15: number = 3;
  TIMELINE_DIVISION_VALUE_30: number = 2;
  TIMELINE_DIVISION_VALUE_60: number = 1;

  placeholderSearch: string;

  mediasOfFolder: any;
  mediaSelected: any;

  searchInputValue: string = '';

  timeSettingNotification: any;

  /**
   * Error message list
   */
  private errorMessageImportFile: string[];

  /**
   * list media (mini media)
   */
  medias: Array<NotificationMedia> = new Array<NotificationMedia>();

  mediasDisplay: Array<NotificationMedia> = new Array<NotificationMedia>();

  listNoticeText: Array<NoticeText> = new Array<NoticeText>();

  listNoticeTextDisplay: Array<NoticeText> = new Array<NoticeText>();
  noticeTextThenSelected: NoticeText = new NoticeText();

  BackgroundColorModeEnum = BackgroundColorModeEnum;

  config: any;

  timezone: string;

  isSaving: boolean;
  /**
   * loadMoreScrollDown
   * @returns
   */
  public loadMoreScrollDown(): void {
    if (this.dataLengthAPI < this.size) {
      return;
    }
    this.getData(this.indexItem, this.size, true);
  }

  /**
   * loadMoreScrollUp
   * @returns
   */
  public loadMoreScrollUp(): void {
    if (this.dataLengthAPI < this.size) {
      return;
    }
    this.getData(this.indexItem, this.size, false);
  }

  /**
   * scrollToElementById
   * @param id
   * @returns
   */
  public scrollToElementById(id: string) {
    if (id == '-1') {
      var myDiv = document.getElementById('tableDiv');
      if (myDiv) {
        myDiv.scrollTop = 0;
      }
      return;
    }
    const element = document.getElementById(id);
    if (!element) {
      setTimeout(() => {
        this.scrollToElementById(id);
      }, 100);
    }
    if (element) {
      element.scrollIntoView();
    }
  }
  isViewJp: boolean = true;

  /**
   * headers
   */
  public headers: any[];
  /**
   * header APIs
   */
  public headerCRUDs: any[];
  // public headerTabEdits: any[];

  public headerAPIs: any[];

  public headerTopAPIs: any[];

  /**
   * true if network OK
   */
  public isNetworkOK: boolean;

  /**
   * apiUpdateData
   */
  private apiUpdateData: string;

  /**
   * timeDateLine
   */
  timeDateLine: string;

  /**
   * lastTimeUpdate
   */
  public lastTimeUpdate;

  /**
   * timeByImg
   */
  private timeByImg;

  /**
   * language key
   */
  languageKey: string;
  /**
   * common object
   */
  private commonObject: Common;
  // isChangePlayList: boolean;
  // stateOfComponent: {
  //   playListSelected: SequenceNotification;
  //   isChangePlayList: boolean;
  // };

  /**
   * save data success
   */
  @Output() saveDataSuccess = new EventEmitter<boolean>();
  dynamicMessages: Array<DynamicMessageSchedule>;
  dynamicMessageSelected: DynamicMessageSchedule;
  oldDynamicMessages: Array<DynamicMessageSchedule>;
  isEditDynamicMessageMode: boolean;
  isEditEmergencyMode: boolean;
  isOnlyTextArea: boolean;
  isOnlyPictureArea: boolean;
  mediaFilesDroppedOperation: any;
  filesData: any;
  mediaIdsToDelete: any;
  private readonly updateStateItemOnMenu = new Subject();
  private readonly propertiesName = {
    dynamicMessage: this.translateService.instant('dialog-message.timetable-operation.dynamic-message'),
    emergencyLinkText: this.translateService.instant('dialog-message.timetable-operation.link-text')
  };
  emergencyData: EmergencyData;
  emergencyMediaIdOld: number;

  regexTime: any;
  formatTime: string;
  haveTimess: boolean = true;
  timeAdd: number = 0;
  item7s: any[] = [];
  tabSelected: number = Constant.OPERATION_SCHEDULE_ENUM;
  Constant = Constant;
  noticeTextSelected: NoticeText;
  sequenceSelected: any;
  previewSelected: any;
  previewSelectedClone: any;
  isEditText: boolean = false;
  notificationMediaValidator: NotificationMediaValidator;
  isTypeList: boolean = true;
  isChangeText: boolean = false;
  isPassText: boolean = true;
  constructor(
    private scheduleMergeService: ScheduleMergeService,
    private scheduleOperationManagerService: ScheduleOperationManagerService,
    private translateService: TranslateService,
    private actionService: MenuActionService,
    private dialogService: DialogService,
    private executingService: ExecutingService,
    private deviceService: DeviceService,
    private apiCustomerService: APICustomerService,
    private commonService: CommonService,
    private dataService: DataService,
    private dynamicMessageService: DynamicMessageService,
    private emergencyDataService: EmergencyDataService,
    private mediaService: MediaService,
    private toast: ToastrService,
    private textHighlightSettingService: TextHighlightSettingService,
    private commonTableService: CommonTableService,
    private customTagService: CustomTagService,
    private changeDetectorRef: ChangeDetectorRef,
    private menuActionService: MenuActionService,
    public readonly store: Store<AppState>
  ) {
    this.subscriptions.push(
      this.actionService.actionShowHiddenItemSetting.subscribe(moduleName => {
        if (moduleName == MODULE_NAME[FIELD_COMPONENT.ScheduleOperationManagerComponent]) {
          this.showHiddenItemSetting();
        }
      })
    );
    this.subscriptions.push(
      this.actionService.actionShowAreaSetting.subscribe(moduleName => {
        if (moduleName == MODULE_NAME[FIELD_COMPONENT.ScheduleOperationManagerComponent]) {
          this.showAreaSetting();
        }
      })
    );
    this.subscriptions.push(
      this.actionService.actionShowAreaItemSetting.subscribe(moduleName => {
        if (moduleName == MODULE_NAME[FIELD_COMPONENT.ScheduleOperationManagerComponent]) {
          this.showAreaItemSetting();
        }
      })
    );
    // insert row
    this.subscriptions.push(
      this.actionService.actionInsertRow.subscribe(module => {
        if (module == MODULE_NAME[FIELD_COMPONENT.ScheduleOperationManagerComponent]) {
          this.insertRow();
        }
      })
    );
    // edit row
    this.subscriptions.push(
      this.actionService.actionEditRow.subscribe(module => {
        if (module == MODULE_NAME[FIELD_COMPONENT.ScheduleOperationManagerComponent]) {
          this.editRow();
        }
      })
    );
    // delete row
    this.subscriptions.push(
      this.actionService.actionDeleteRow.subscribe(module => {
        if (module == MODULE_NAME[FIELD_COMPONENT.ScheduleOperationManagerComponent]) {
          this.deleteRow();
        }
      })
    );
    // duplicate row
    this.subscriptions.push(
      this.actionService.actionDuplicateRow.subscribe(module => {
        if (module == MODULE_NAME[FIELD_COMPONENT.ScheduleOperationManagerComponent]) {
          this.duplicateRow();
        }
      })
    );
    // subscribe for clear field data
    this.subscriptions.push(
      this.actionService.actionClearField.subscribe(module => {
        if (module == MODULE_NAME[FIELD_COMPONENT.ScheduleOperationManagerComponent]) {
          this.clearField();
        }
      })
    );
    // updatedate
    this.subscriptions.push(
      this.actionService.actionUpdateData.subscribe(module => {
        if (module == MODULE_NAME[FIELD_COMPONENT.ScheduleOperationManagerComponent]) {
          this.checkBeforeUpdate();
        }
      })
    );
    this.subscriptions.push(
      this.actionService.actionDynamicMessage.subscribe(module => {
        if (module == MODULE_NAME[FIELD_COMPONENT.ScheduleOperationManagerComponent]) {
          this.openDynamicMessage();
        }
      })
    );
    this.subscriptions.push(
      this.actionService.actionEmergency.subscribe(module => {
        if (module == MODULE_NAME[FIELD_COMPONENT.ScheduleOperationManagerComponent]) {
          this.openEmergency();
        }
      })
    );
    this.subscriptions.push(
      this.actionService.actionImportMedia.subscribe(module => {
        if (
          module == MODULE_NAME[FIELD_COMPONENT.ScheduleOperationManagerComponent] &&
          this.tabSelected == Constant.NOTIFICATION_REGISTRATION_ENUM
        ) {
          this.importMediaFile();
        }
      })
    );
    this.subscriptions.push(
      this.actionService.actionAdd.subscribe(module => {
        if (module == MODULE_NAME[FIELD_COMPONENT.ScheduleOperationManagerComponent]) {
          this.addNewSequence();
        }
      })
    );
    this.subscriptions.push(
      this.actionService.actionEdit.subscribe(module => {
        if (module == MODULE_NAME[FIELD_COMPONENT.ScheduleOperationManagerComponent]) {
          this.editNewSequence();
        }
      })
    );
    this.subscriptions.push(
      this.actionService.actionDuplicate.subscribe(module => {
        if (module == MODULE_NAME[FIELD_COMPONENT.ScheduleOperationManagerComponent]) {
          this.duplicateNewSequence();
        }
      })
    );
    this.subscriptions.push(
      this.actionService.actionDelete.subscribe(module => {
        if (module == MODULE_NAME[FIELD_COMPONENT.ScheduleOperationManagerComponent]) {
          this.deleteNewSequence();
        }
      })
    );
    this.subscriptions.push(
      this.actionService.actionEditDetail.subscribe(module => {
        if (module == MODULE_NAME[FIELD_COMPONENT.ScheduleOperationManagerComponent]) {
          this.editText();
        }
      })
    );
    this.subscriptions.push(
      this.actionService.actionDeleteItem.subscribe(module => {
        if (module == MODULE_NAME[FIELD_COMPONENT.ScheduleOperationManagerComponent]) {
          this.deleteItem();
        }
      })
    );
    this.subscriptions.push(
      this.actionService.actionSetting.subscribe(module => {
        if (module == MODULE_NAME[FIELD_COMPONENT.ScheduleOperationManagerComponent]) {
          this.timelineSetting();
        }
      })
    );

    this.subscriptions.push(
      this.translateService.onLangChange.subscribe(() => {
        this.languageKey = this.commonService.getCommonObject().setting?.language;
        this.lastTimeUpdate = Helper.formatString(
          this.translateService.instant(`timetable-operation-manager.last-time-update`),
          Helper.updateLanguageLastTime(this.timeByImg, this.languageKey)
        );
        this.checkViewJP();
      })
    );
    this.commonObject = commonService.getCommonObject();
    this.subscriptions.push(
      this.updateStateItemOnMenu.subscribe(() => {
        this.dataService.sendData([
          `${MODULE_NAME[FIELD_COMPONENT.ScheduleOperationManagerComponent]}:isEditDynamicMessageMode`,
          this.isEditDynamicMessageMode
        ]);
        this.dataService.sendData([
          `${MODULE_NAME[FIELD_COMPONENT.ScheduleOperationManagerComponent]}:isEditEmergencyMode`,
          this.isEditEmergencyMode
        ]);
      })
    );
    this.isRoot = this.commonService.getCommonObject().userIdString == Constant.ROOT;
    this.subscriptions.push(
      this.menuActionService.actionSave.subscribe(module => {
        if (module == MODULE_NAME[FIELD_COMPONENT.ScheduleOperationManagerComponent]) {
          if (this.playlistSelected?.isEdit || !_.isEqual(this.sequenceOriginClone, this.sequenceOrigin)) {
            const check = this.savePlaylistBeforeLeave();
            if (!check) {
              this.saveDataSuccess.emit(false);
            } else {
              this.saveDataSuccess.emit(true);
            }
          }
        }
      })
    );
    // this.subscriptions.push(
    //   this.store
    //     .select(state => state)
    //     .subscribe((componentState: any) => {
    //       this.stateOfComponent = {
    //         playListSelected: componentState?.playListSelected,
    //         isChangePlayList: componentState?.isChangePlayList
    //       };
    //     })
    // );
  }

  async ngOnInit(): Promise<void> {
    this.languageKey = this.commonObject?.setting?.language;
    this.checkViewJP();
    this.getApiUpdateData();
    this.commonTableService.getValueCommonTableByKey(Constant.KEY_AREA_NAME).subscribe((res: any) => {
      if (!res || res.value == 0) {
        return;
      }
      const areaId = res.value;
      if (areaId) {
        this.customTagService.getCustomTag().subscribe(
          data => {
            const listAreaGroup = data[2].elements;
            const index = listAreaGroup.findIndex(e => e.id == areaId);
            this.areaName = listAreaGroup[index].name;
            if (this.areaName) {
              this.getAllDevices();
              this.getInformationScheduleOperation();
              this.getListSelectedItem();
            }
          },
          error => {
            this.dialogService.showDialog(DialogMessageComponent, {
              data: {
                title: `Error`,
                text: `An error has occurred. Please try again.`
              }
            });
          }
        );
      }
      this.dataService.sendData([Constant.HAS_AREA, JSON.parse(res.value) == 0 ? false : true]);
      this.chooseTab(Constant.OPERATION_SCHEDULE_ENUM);
    });
    this.placeholderSearch = this.translateService.instant('simple-signage-editor.search');
    this.dataService.currentData.subscribe(data => {
      if (data[0] == Constant.IS_CHANGE_TIME_ZONE) {
        if (data[1]) {
          if (this.areaName) {
            this.isEditRow = false;
            this.dataService.sendData(['isEditRow', this.isEditRow]);
            this.scheduleSelectedClone = null;
            this.getInformationScheduleOperation();
          }
          this.timezone = this.commonService
            .getCommonObject()
            .setting.timezone.name.split(' ')[0]
            .replace(')', '')
            .replace('(', '')
            .replace('GMT', '');
          if (!this.timezone) {
            this.timezone = 'Z';
          }
        }
        this.placeholderSearch = this.translateService.instant('simple-signage-editor.search');
        const currentDate = Helper.getCurrentByTimezoneSetting(this.commonObject);
        const minDate = _.cloneDeep(currentDate);
        minDate.setDate(minDate.getDate() + 1);
        const maxDate = _.cloneDeep(currentDate);
        maxDate.setDate(maxDate.getDate());
        maxDate.setFullYear(maxDate.getFullYear() + 2);
        this.config = {
          showWeekNumbers: false,
          format: 'YYYY-MM-DD',
          firstDayOfWeek: 'mo',
          unSelectOnClick: false,
          locale: Helper.getLocale(this.languageKey),
          min: this.formatDate(minDate),
          max: this.formatDate(maxDate)
        };
      }
    });
    this.dataService.sendData([Constant.IS_ROOT, this.isRoot]);
    //set timeline
    this.timelineDivision = this.TIMELINE_DIVISION_VALUE_60;
    this.timelineDivisionDisplay = this.TIMELINE_DIVISION_VALUE_60;
    this.countItem = 4;
    this.commonTableService.getValueCommonTableByKey(Constant.KEY_TIME_SETTING_NOTIFICATION).subscribe(
      data => {
        this.timeSettingNotification = data ? JSON.parse(data.value) : Constant.TIME_DATE_LINE_DEFAULT;
      },
      error => Helper.handleError(error, this.translateService, this.dialogService)
    );
    const currentDate = Helper.getCurrentByTimezoneSetting(this.commonObject);
    const minDate = _.cloneDeep(currentDate);
    minDate.setDate(minDate.getDate() + 1);
    const maxDate = _.cloneDeep(currentDate);
    maxDate.setDate(maxDate.getDate());
    maxDate.setFullYear(maxDate.getFullYear() + 2);
    this.config = {
      showWeekNumbers: false,
      format: 'YYYY-MM-DD',
      firstDayOfWeek: 'mo',
      unSelectOnClick: false,
      locale: Helper.getLocale(this.languageKey),
      min: this.formatDate(minDate),
      max: this.formatDate(maxDate)
    };
    this.timezone = this.commonService
      .getCommonObject()
      .setting.timezone.name.split(' ')[0]
      .replace(')', '')
      .replace('(', '')
      .replace('GMT', '');
    if (!this.timezone) {
      this.timezone = 'Z';
    }
  }

  ngDoCheck() {
    if (this.tabSelected == Constant.NOTIFICATION_REGISTRATION_ENUM) {
      this.isChangedData = !_.isEqual(this.sequenceOriginClone, this.sequenceOrigin);
    }
  }

  /**
   * checkViewJP
   */
  checkViewJP() {
    const tmpView = this.translateService.instant('schedule-operation-manager.suspension');
    this.isViewJp = tmpView && tmpView.length > 4 ? false : true;
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  /**
   * getData
   * @param index
   * @param size
   * @param isScrollDown
   * @param loadLast
   * @param scrollTo
   * @returns
   */
  getData(index, size, isScrollDown, loadLast?, scrollTo?) {
    if (index < 0) {
      return;
    } else if (!isScrollDown && index <= size * 3) {
      return;
    }
    if (this.isEditRow) {
      return;
    }
    this.indexItem = index;
    let dataArr;
    this.scheduleOperationManagerService.getScheduleOperationFromAPI(index, size, isScrollDown).subscribe(data => {
      if (data && data.length > 0) {
        if (isScrollDown) {
          if (loadLast) {
            dataArr = data;
            this.indexItem = index + size;
          } else {
            //if scroll down => push data
            dataArr = [...this.schedulesDaily, ...data];
            this.indexItem += size;
          }
        } else {
          //if scroll up => unshift data
          dataArr = [...data, ...this.schedulesDaily];
          this.indexItem -= size;
          this.scrollToElementById('0'); //scroll to id (O) before add data.
          setTimeout(() => {
            this.scrollToElementById((this.dataLengthAPI < index ? this.dataLengthAPI + size - index : size) + '');
          }, 300);
        }
        this.schedulesDaily = this.scheduleOperationManagerService.arrConvertData(index, size, isScrollDown, dataArr);
        if (scrollTo) {
          setTimeout(() => {
            this.scrollToElementById(parseInt(scrollTo) > this.schedulesDaily.length ? this.schedulesDaily.length - 1 : scrollTo);
          }, 500);
          // this.scrollToElementById(scrollTo);
        }

        if (!this.isLoadHeader && this.schedulesDaily) {
          this.addRowToHeader();
        }
        const indexSelected = this.schedulesDaily.findIndex(e => e.key == this.scheduleSelected?.key);
        if (indexSelected == -1) {
          this.scheduleSelected = null; // reset selected if load more row out size of last seleted
          this.sendDataEnableEdit();
        }
      }
    });
  }

  /**
   * add row to header
   */
  public addRowToHeader(): void {
    this.scheduleOperationManagerService.getShowHiddenItem(this.areaName).subscribe(
      e => {
        if (e && e.headers && e.headerTops) {
          this.headerAPIs = e.headers;
          this.headerTopAPIs = e.headerTops;
          this.checkDataS3WithDB(); //fix tam
        } else {
          this.checkDataS3WithDB();
          this.headerTopAPIs = [
            { label: { en: 'Temporary suspension', ja: '臨時運休' }, field: 'suspension', isActive: true },
            { label: { en: 'Delay', ja: '遅延' }, field: 'hold', isActive: true },
            { label: { en: 'Arrival', ja: '到着' }, field: 'arrive', isActive: true },
            { label: { en: 'Priority', ja: '優先' }, field: 'priority', isActive: true }
          ];
        }
      },
      err => {
        this.checkDataS3WithDB();
      }
    );
  }

  /**
   * addHeader
   */
  addHeader() {
    const headers = [];
    const headerCRUDs = [];
    const headerLabelsFromAPI = this.scheduleOperationManagerService.listScheduleOperationFromAPI.headers
      ? JSON.parse(this.scheduleOperationManagerService.listScheduleOperationFromAPI.headers)
      : null;
    const listReject = [
      'calculatedTime',
      'suspension',
      'hold',
      'arrive',
      'priority',
      'color',
      'index',
      'checkTime',
      'notIn35Min',
      'blink',
      'isDelete',
      'isDeleteRowInsert',
      'isEdit',
      'isEditHold',
      'isEditArrive',
      'isEditPriority',
      'isEditSuspension',
      'key',
      'itemEdit',
      'suspention'
    ];
    const listRejectCRUD = [
      'calculatedTime',
      'suspension',
      'suspention',
      'hold',
      'arrive',
      'priority',
      'color',
      'index',
      'checkTime',
      'notIn35Min',
      'blink',
      'isEdit',
      'isDelete',
      'isDeleteRowInsert',
      'isEditHold',
      'isEditArrive',
      'isEditPriority',
      'isEditSuspension',
      'key',
      'itemEdit'
    ];
    for (const headerAPI of this.headerAPIs) {
      if (headerAPI.isActive) {
        headers.push(headerAPI);
      } else {
        listReject.push(headerAPI.field);
      }
    }
    headers.sort((a, b) => (a.sortOrder > b.sortOrder ? 1 : -1));

    const headerRows = JSON.parse(JSON.stringify(this.scheduleOperationManagerService.listScheduleOperationFromAPI.entry[0]));
    for (const header in headerRows) {
      let label = header;
      if (headerLabelsFromAPI) {
        label = headerLabelsFromAPI[headers.length];
      }
      const re = listReject.findIndex(e => e == header);
      if (re == -1) {
        const i = headers.findIndex(e => e.field == header);
        if (i == -1) {
          headers.push({ label: label, field: header });
        }
      }
      const reCrud = listRejectCRUD.findIndex(e => e == header);
      if (reCrud == -1) {
        if (headerLabelsFromAPI) {
          label = headerLabelsFromAPI[headerCRUDs.length];
        }
        headerCRUDs.push({ label: label, field: header });
      }
    }
    this.headers = headers;
    this.headerCRUDs = headerCRUDs;

    this.isLoadHeader = true;
  }

  /**
   * checkDataS3WithDB
   * @returns
   */
  checkDataS3WithDB() {
    if (
      !this.scheduleOperationManagerService.listScheduleOperationFromAPI.entry ||
      !this.scheduleOperationManagerService.listScheduleOperationFromAPI.entry[0]
    ) {
      return;
    }
    const headers = [];
    const headerRows = JSON.parse(JSON.stringify(this.scheduleOperationManagerService.listScheduleOperationFromAPI.entry[0]));
    const listReject = [
      'calculatedTime',
      'hold',
      'arrive',
      'priority',
      'suspension',
      'color',
      'index',
      'key',
      'itemEdit',
      'checkTime',
      'notIn35Min',
      'blink',
      'suspention'
    ];
    let index = 0;

    const headerLabelsFromAPI = this.scheduleOperationManagerService.listScheduleOperationFromAPI.headers
      ? JSON.parse(this.scheduleOperationManagerService.listScheduleOperationFromAPI.headers)
      : null;
    for (const header in headerRows) {
      let label = header;
      const re = listReject.findIndex(e => e == header);
      if (re == -1) {
        const i = headers.findIndex(e => e.field == header);
        if (i == -1) {
          if (headerLabelsFromAPI) {
            label = headerLabelsFromAPI[headers.length];
          }
          headers.push({ label: label, field: header, isActive: true, sortOrder: index++ });
          if (this.headerAPIs) {
            for (const dataTmpApi of this.headerAPIs) {
              if (dataTmpApi.field == header) {
                headers[headers.length - 1].checkApi = true;
                if (dataTmpApi.label != label) {
                  headers[headers.length - 1].checkApi = false;
                }
                headers[headers.length - 1].sortOrder = dataTmpApi.sortOrder;
                headers[headers.length - 1].isActive = dataTmpApi.isActive;
              }
            }
          }
        }
      }
    }
    let checkApi = true;
    for (const header of headers) {
      if (!header.checkApi) {
        //convert sort order. call api save item;
        checkApi = false;
      }
    }
    if (checkApi) {
      this.addHeader();
      return;
    }
    this.headers = headers;
    this.headerCRUDs = headers;
    this.isLoadHeader = true;
  }

  /**
   * resetHeader
   */
  resetHeader() {
    const headers = [];
    const listReject = [
      'calculatedTime',
      'hold',
      'arrive',
      'priority',
      'suspension',
      'suspention',
      'color',
      'index',
      'key',
      'itemEdit',
      'checkTime',
      'notIn35Min',
      'blink',
      'isDelete',
      'isDeleteRowInsert',
      'isEdit',
      'isEditHold',
      'isEditArrive',
      'isEditPriority',
      'isEditSuspension'
    ];
    const headerRows = JSON.parse(JSON.stringify(this.schedulesDaily[0]));
    for (const header in headerRows) {
      const re = listReject.findIndex(e => e == header);
      if (re == -1) {
        headers.push({ label: header, field: header });
      }
    }
    this.headers = headers;
    this.headerCRUDs = headers;
    this.isLoadHeader = true;
  }

  /**
   * enlarge preview
   */
  public enlargePreview(): void {
    this.isEnlargePreview = !this.isEnlargePreview;
  }

  /**
   * select schedule
   * @param index
   * @returns
   */
  public selectSchedule(index: number): void {
    if (this.isEditRow) {
      return;
    }
    if (this.checkRealTime(this.schedulesDaily[index])) {
      this.scheduleSelected = this.schedulesDaily[index];
      this.sendDataEnableEdit();
    }
  }

  /**
   * change prevent
   * @param index
   * @returns
   */
  public changePrevent(index: number): void {
    if (!this.checkEnableEdit(index)) {
      return;
    }
    if (this.schedulesDaily[index].delete || this.schedulesDaily[index].isDelete) {
      return;
    }
    if (
      this.schedulesDaily[index].arrive ||
      this.schedulesDaily[index].suspension ||
      this.schedulesDaily[index].suspention ||
      this.schedulesDaily[index].isEditSuspension
    ) {
      return;
    }
    if (!this.schedulesDaily[index].arrive && this.schedulesDaily[index].isEditArrive) {
      return;
    }
    if (this.schedulesDaily[index].isEdit) {
      return;
    }
    this.schedulesDaily[index].hold = !this.schedulesDaily[index].hold;
  }

  /**
   * changeArrial
   * @param index
   * @returns
   */
  public changeArrival(index: number): void {
    if (!this.checkEnableEdit(index)) {
      return;
    }
    if (this.schedulesDaily[index].delete || this.schedulesDaily[index].isDelete) {
      //check if delete -> return;
      return;
    }
    if (this.schedulesDaily[index].isEdit) {
      return;
    }
    if (this.schedulesDaily[index].suspension || this.schedulesDaily[index].suspention || this.schedulesDaily[index].isEditSuspension) {
      return;
    }
    this.schedulesDaily[index].arrive = !this.schedulesDaily[index].arrive;
  }

  public changeSuspension(index: number): void {
    if (!this.checkEnableEdit(index)) {
      return;
    }
    if (this.schedulesDaily[index].delete || this.schedulesDaily[index].isDelete) {
      //check if delete -> return;
      return;
    }
    if (this.schedulesDaily[index].isEdit) {
      return;
    }
    if (
      this.schedulesDaily[index].hold ||
      this.schedulesDaily[index].priority ||
      this.schedulesDaily[index].arrive ||
      this.schedulesDaily[index].isEditHold ||
      this.schedulesDaily[index].isEditArrive ||
      this.schedulesDaily[index].isEditPriority
    ) {
      return;
    }
    if (this.checkRealTime(this.schedulesDaily[index])) {
      this.schedulesDaily[index].suspension = !this.schedulesDaily[index].suspension;
    }
  }

  /**
   * checkEnableEdit
   * @param index
   * @returns
   */
  checkEnableEdit(index: number): boolean {
    if (this.isEditRow) {
      return false;
    }
    return true;
  }

  /**
   * change priority
   * @param index
   * @returns
   */
  public changePriority(index: number): void {
    if (!this.checkEnableEdit(index)) {
      return;
    }
    if (this.schedulesDaily[index].delete || this.schedulesDaily[index].isDelete) {
      //check if delete -> return;
      return;
    }
    if (this.schedulesDaily[index].isEdit) {
      return;
    }
    if (this.schedulesDaily[index].suspension || this.schedulesDaily[index].suspention || this.schedulesDaily[index].isEditSuspension) {
      return;
    }
    if (this.checkRealTime(this.schedulesDaily[index])) {
      this.schedulesDaily[index].priority = !this.schedulesDaily[index].priority;
    }
  }

  /**
   * checkRealTime
   * @param item
   * @returns
   */
  checkRealTime(item): boolean {
    if (item && !item.time) {
      return true;
    }
    // true is time(item) > real time.
    if (item.checkTime) {
      // if checked = false -> return.
      return false;
    } else if (item.notIn35Min) {
      return true;
    }
    const dateNow = new Date(Helper.getCurrentDateByTimeZone(this.commonObject));
    const time = moment(dateNow)
      .format(this.formatTime)
      .replace(/:?/g, '');
    const timeInt = parseInt(time) + (this.formatTime == 'HH:mm:ss' ? this.timeAdd * 10000 : this.timeAdd * 100);
    const timeRow = parseInt(item.time.replace(/:?/g, ''));
    if (timeRow > timeInt) {
      if ((this.formatTime == 'HH:mm:ss' && timeRow - timeInt > 6000) || (this.formatTime == 'HH:mm' && timeRow - timeInt > 60)) {
        item.notIn35Min = true;
      }
      return true;
    } else {
      if (this.scheduleSelected && this.scheduleSelected.key == item.key) {
        if (this.scheduleSelected.key == item.key && this.isEditRow) {
          return true;
        }
        this.scheduleSelected = null;
        this.sendDataEnableEdit();
      }
      item.checkTime = true;
      return false;
    }
  }

  /**
   * get data current day
   * @returns
   */
  private getDataCurrentDay(): TimetableDaily[] {
    let date = Helper.getCurrentByTimezoneSetting(this.commonObject, false);
    let year = date.getFullYear();
    let month = date.getMonth() + 1;
    let day = date.getDate();
    const hoursAndMinutes = date.getHours() * 3600 + date.getMinutes() * 60;
    const timeDateLineSecond = parseInt(this.timeDateLine.split(':')[0]) * 3600 + parseInt(this.timeDateLine.split(':')[1]) * 60;
    if (timeDateLineSecond > hoursAndMinutes) {
      day = day - 1;
    }
    let timeZone = this.commonService
      .getCommonObject()
      .setting.timezone.name.split(' ')[0]
      .replace(')', '')
      .replace('(', '');
    this.labelSchedule = Helper.formatString(
      this.translateService.instant('schedule-operation-manager.label-schedule'),
      `${year}`,
      `${month}`,
      `${day}`
    );

    this.scheduleMergeService
      .getDataCurrentDay(year.toString(), month.toString().padStart(2, '0'), day.toString().padStart(2, '0'), timeZone, this.areaName)
      .subscribe(data => {
        if (data) {
          this.scheduleOperationManagerService.listScheduleOperationFromAPI = data;
          if (data && data.item7s) this.item7s = JSON.parse(data.item7s);
          if (data && data.timeAdd) {
            this.timeAdd = data.timeAdd;
          }
          this.scheduleOperationManagerService.convertDataOperationApi();
          this.addRowToHeader();
          if (data && data.entry && data.entry[0]) {
            this.dataLengthAPI = this.scheduleOperationManagerService.listScheduleOperationFromAPI.entry.length;
            this.checkTypeDate(data.entry[0]);
            this.findFirstKey();
          }
        } else {
          this.scheduleOperationManagerService.listScheduleOperationFromAPI = undefined;
          this.schedulesDaily = undefined;
        }
      });
    return this.timetablesDaily;
  }

  /**
   * reload data
   */
  public reloadData(isUpdateData = false) {
    let date = Helper.getCurrentByTimezoneSetting(this.commonObject, false);
    let year = date.getFullYear();
    let month = date.getMonth() + 1;
    let day = date.getDate();
    // reset all options to default;
    this.schedulesDaily = [];
    this.scheduleEditArray = [];
    this.scheduleInsertArray = [];
    this.scheduleDeleteArray = [];
    this.indexItem = 0;
    //if have find to Schedule -> no set value selected to null -> target schedule selected
    this.scheduleSelected = null;
    this.sendDataEnableEdit();
    let timeZone = this.commonService
      .getCommonObject()
      .setting.timezone.name.split(' ')[0]
      .replace(')', '')
      .replace('(', '');
    const hoursAndMinutes = date.getHours() * 3600 + date.getMinutes() * 60;
    const timeDateLineSecond = parseInt(this.timeDateLine.split(':')[0]) * 3600 + parseInt(this.timeDateLine.split(':')[1]) * 60;
    if (timeDateLineSecond > hoursAndMinutes) {
      day = day - 1;
    }
    this.scheduleMergeService
      .getDataCurrentDay(year.toString(), month.toString().padStart(2, '0'), day.toString().padStart(2, '0'), timeZone, this.areaName)
      .subscribe(data => {
        if (!data) {
          return;
        }
        this.scheduleOperationManagerService.listScheduleOperationFromAPI = data;
        this.scheduleOperationManagerService.convertDataOperationApi();
        if (isUpdateData) {
          //click update data. show dialog if reload done
          this.dialogService.showDialog(DialogMessageComponent, {
            data: {
              title: this.translateService.instant('dialog-confirm.title'),
              text: this.translateService.instant('schedule-operation-manager.update-success')
            }
          });
        }
        this.findFirstKey();
      });
  }

  /**
   * findFirstKey
   * @returns
   */
  findFirstKey() {
    for (const [index, schedule] of this.scheduleOperationManagerService.listScheduleOperationFromAPI.entry.entries()) {
      if (this.checkRealTime(schedule)) {
        this.scheduleSelected = schedule;
        this.sendDataEnableEdit();
        this.loadDataWithKeyNum(index + 1);
        return;
      }
      if (schedule.hold || this.checkRealTime(schedule)) {
        this.loadDataWithKeyNum(index + 1);
        return;
      }
    }
    this.loadDataWithKeyNum(this.scheduleOperationManagerService.listScheduleOperationFromAPI.entry.length);
    return;
  }

  /**
   * loadDataWithKeyNum
   * @param key
   */
  loadDataWithKeyNum(key) {
    this.indexItem = 0;
    const numSize = (key - (key % this.size)) / this.size;
    let numIndexItem;
    let scrollTo;
    this.schedulesDaily = [];
    if (numSize < 2) {
      numIndexItem = 0;
      scrollTo = key - 5;
    } else if ((numSize + 1) * this.size <= this.dataLengthAPI) {
      numIndexItem = this.size * (numSize - 1);
      scrollTo = (key % this.size) - 5 + this.size; //(key % this.size == 0 ? 0 : this.size);
    } else {
      numIndexItem = this.size * (numSize - 2);
      scrollTo = (key % this.size) - 5 + this.size * 2;
    }
    this.getData(numIndexItem, this.size * 3, true, false, (scrollTo < 0 ? -1 : scrollTo) + '');
  }

  /**
   * checkTypeDate
   * @param schedule
   */
  checkTypeDate(schedule) {
    //check type time is hh:mm or hh:mm:ss
    if (schedule.time.length < 6) {
      this.haveTimess = false;
      this.formatTime = 'HH:mm';
      this.regexTime = Helper.formatTimeRegex(Constant.FORMAT_TIME_MINUTE1_REGEX_0, this.timeDateLine);
    } else {
      this.haveTimess = true;
      this.formatTime = 'HH:mm:ss';
      this.regexTime = Helper.formatTimeRegex(Constant.FORMAT_TIME_SECOND_REGEX_0, this.timeDateLine);
    }
  }

  /**
   * operation information setting
   */
  private showHiddenItemSetting(): void {
    // open popup
    this.dialogService.showDialog(
      DialogShowHiddenItemSettingSettingComponent,
      { data: { schedulesDaily: this.schedulesDaily, languageKey: this.languageKey, areaName: this.areaName } },
      result => {
        if (result) {
          this.addRowToHeader();
        }
      }
    );
  }

  /**
   * operation information setting
   */
  private showAreaSetting(): void {
    this.commonTableService.getValueCommonTableByKey(Constant.KEY_AREA_NAME).subscribe(data => {
      this.dialogService.showDialog(
        DialogAreaSettingComponent,
        {
          data: { commonTable: data ?? new CommonTable(Constant.KEY_AREA_NAME, null) }
        },
        result => {
          if (result == 'cancel') {
            return;
          } else if (!result || result == 0) {
            this.areaName = null;
            this.reloadData();
          } else {
            const areaId = result;
            if (areaId) {
              this.customTagService.getCustomTag().subscribe(
                data => {
                  const listAreaGroup = data[2].elements;
                  const index = listAreaGroup.findIndex(e => e.id == areaId);
                  this.areaName = listAreaGroup[index].name;
                  if (this.areaName) {
                    this.scheduleSelectedClone = undefined;
                    this.schedulesDaily = [];
                    this.scheduleEditArray = [];
                    this.scheduleInsertArray = [];
                    this.scheduleDeleteArray = [];
                    this.indexItem = 0;
                    this.scheduleSelected = null;
                    this.getAllDevices();
                    this.getInformationScheduleOperation();
                    this.getListSelectedItem();
                  }
                },
                error => {
                  this.dialogService.showDialog(DialogMessageComponent, {
                    data: {
                      title: `Error`,
                      text: `An error has occurred. Please try again.`
                    }
                  });
                }
              );
            }
          }
        }
      );
    });
  }

  /**
   * getListSelectedItem
   */
  private getListSelectedItem() {
    this.scheduleOperationManagerService.getAreaItemSetting(this.areaName).subscribe(data => {
      if (!data) {
        this.listSelectedItemName = [];
        return;
      }
      this.listSelectedItem = data;
      this.listSelectedItemName = this.listSelectedItem.map(e => e.label);
    });
  }

  /**
   * getDataSelect
   * @param header
   * @returns
   */
  public getDataSelect(header: string) {
    return this.listSelectedItem.find(item => item.label == header).value.split(',');
  }

  /**
   * operation information setting
   */
  private showAreaItemSetting(): void {
    this.scheduleOperationManagerService.getAreaItemSetting(this.areaName).subscribe(res => {
      const data = {
        headerCRUDs: this.headerCRUDs,
        languageKey: this.languageKey,
        value: res,
        areaGroup: this.areaName
      }; // open popup
      this.dialogService.showDialog(DialogAreaItemSettingComponent, { data }, result => {
        if (result) {
          this.getListSelectedItem();
        }
      });
    });
  }

  /**
   * change device
   * @param deviceId
   * @returns
   */
  public changeDevice(deviceId: any): void {
    this.deviceIdSelected = deviceId;
    this.deviceSelected = deviceId != -1 ? this.devices.find(item => item.id == deviceId) : undefined;
    this.lastTimeUpdate = null;
    this.timeByImg = null;
    if (!this.deviceSelected) {
      this.dataService.sendData([Constant.IS_NETWORK_OK, false]);
      this.isNetworkOK = false;
      return;
    }
    //set captureUrl to null
    this.deviceSelected.captureUrl = null;
    // call API status to check network controller device
    this.apiCustomerService.getCurrentStatusRequest([this.deviceSelected.registrationId]).subscribe(data => {
      this.isNetworkOK = !!_.get(data?.['completed'], `[0]`, undefined);
      // update information for device selected
      let deviceCompleted = data[Constant.COMPLETE_STATUS]?.find(item => this.deviceSelected.registrationId === item.id);
      this.dataService.sendData([Constant.IS_NETWORK_OK, !!deviceCompleted]);
    });
  }

  /**
   * get all devices
   */
  private getAllDevices(): void {
    this.deviceService.getDevice().subscribe(
      data => {
        this.devices = Helper.convertDataToDeviceOperation(data)?.filter(device => device.customTag2?.name == this.areaName);
      },
      error => Helper.handleError(error, this.translateService, this.dialogService)
    );
  }

  /**
   * Capture screen device
   */
  public captureScreenDevice(): void {
    if (!this.deviceSelected) {
      this.handleShowMessage(Constant.ERROR_TITLE, 'no-device');
    }
    // call API status to check network controller device
    this.apiCustomerService.getCurrentStatusRequest([this.deviceSelected.registrationId]).subscribe(data => {
      this.isNetworkOK = !!_.get(data?.['completed'], `[0]`, undefined);
      // update information for device selected
      let deviceCompleted = data[Constant.COMPLETE_STATUS]?.find(item => this.deviceSelected.registrationId === item.id);
      this.dataService.sendData([Constant.IS_NETWORK_OK, !!deviceCompleted]);
      // check network OK/NG
      if (!deviceCompleted) {
        return;
      }
      // network OK => call API screen capture
      this.handleCallAPIScreenCapture();
    });
  }

  /**
   * handle call api screen capture
   */
  private handleCallAPIScreenCapture() {
    const payload = {
      device: this.deviceSelected.registrationId
    };
    this.apiCustomerService.captureScreenDevice(payload).subscribe(
      () => {
        this.executingService.executing();
        setTimeout(() => {
          this.deviceService.getDeviceScreenCaptureUrl(this.deviceSelected.registrationId).subscribe(
            data => {
              this.executingService.executed();
              if (!data) {
                return;
              }
              this.timeByImg = data['time'];
              this.lastTimeUpdate = Helper.formatString(
                this.translateService.instant(`timetable-operation-manager.last-time-update`),
                Helper.updateLanguageLastTime(this.timeByImg, this.languageKey)
              );
              this.deviceSelected.captureUrl = data['url'];
            },
            () => {
              this.executingService.executed();
              this.handleShowMessage(Constant.ERROR_TITLE, Constant.COMMON_ERROR);
            }
          );
        }, 3000);
      },
      () => this.handleShowMessage(Constant.ERROR_TITLE, 'call-screen-failed')
    );
  }

  /**
   * Handle show message
   *
   * @param title
   * @param messageCode
   */
  private handleShowMessage(title: string, messageCode: string): void {
    this.dialogService.showDialog(DialogMessageComponent, {
      data: {
        title: this.translateService.instant(`schedule-operation-manager.message.${title}`),
        text: this.translateService.instant(`schedule-operation-manager.message.${messageCode}`)
      }
    });
  }
  pushDataToListView(objAdd, indexTable) {
    this.schedulesDaily.splice(indexTable, 0, objAdd);
    this.scheduleSelected = this.schedulesDaily[indexTable];
    this.sendDataEnableEdit();
  }
  pushDataAddToList(objAdd) {
    this.scheduleOperationManagerService.listScheduleOperationFromAPI.entry.push(objAdd);
    this.sortWithTimeEdit(objAdd.index);
  }
  sortWithTimeEdit(indexData) {
    this.scheduleOperationManagerService.listScheduleOperationFromAPI.entry.sort((a, b) =>
      parseInt(a.time.replace(/:?/g, '')) >= parseInt(b.time.replace(/:?/g, '')) ? 1 : -1
    );

    this.scheduleOperationManagerService.listScheduleOperationFromAPI.entry.forEach((e, index) => {
      e.key = index + 1;
      if (e.isEdit && e.index > 0) {
        let scheduleI = this.scheduleEditArray.findIndex(tmp => tmp.index == e.index);
        if (scheduleI != -1) {
          this.scheduleEditArray[scheduleI].key = index + 1;
        }
      }
      if (e.index < 0) {
        let scheduleI = this.scheduleInsertArray.findIndex(tmp => tmp.index == e.index);
        if (scheduleI != -1) {
          this.scheduleInsertArray[scheduleI].key = index + 1;
        }
      }
      if (e.isDelete) {
        let scheduleI = this.scheduleDeleteArray.findIndex(tmp => tmp.index == e.index);
        if (scheduleI != -1) {
          this.scheduleDeleteArray[scheduleI].key = index + 1;
        }
      }
    });
    const keySelected = this.scheduleOperationManagerService.listScheduleOperationFromAPI.entry.findIndex(e => e.index == indexData);
    this.loadDataWithKeyNum(keySelected);
  }

  /**
   * save edit item
   */
  public saveEditItem(): void {
    delete this.scheduleSelectedClone.notIn35Min; //clear check with edit or insert
    delete this.scheduleSelectedClone.checkTime; //clear check with edit or insert
    const time = this.scheduleSelectedClone.time;
    this.nameDisplayClone = undefined;
    let match = new RegExp(this.regexTime).exec(time);
    if (match == null) {
      this.handleShowMessage('error', 'time-invalid');
      return;
    }
    if (
      (this.scheduleSelectedClone.time.length < 8 && this.haveTimess) ||
      (this.scheduleSelectedClone.time.length < 5 && !this.haveTimess)
    ) {
      this.scheduleSelectedClone.time = '0' + this.scheduleSelectedClone.time;
    }
    let timeChange = _.clone(this.scheduleSelectedClone.time);
    const dateNow = new Date(Helper.getCurrentDateByTimeZone(this.commonObject));
    const now = moment(dateNow)
      .format(this.formatTime)
      .replace(/:?/g, '');
    const timeInt = parseInt(now) + (this.formatTime == 'HH:mm:ss' ? this.timeAdd * 10000 : this.timeAdd * 100);
    const numTimeDateLine =
      this.formatTime == 'HH:mm:ss' ? parseInt(this.timeDateLine.replace(/:?/g, '')) * 100 : parseInt(this.timeDateLine.replace(/:?/g, ''));
    let timeRow = parseInt(time.replace(/:?/g, ''));
    if (timeRow < numTimeDateLine) {
      timeRow = timeRow + (this.formatTime == 'HH:mm:ss' ? 24 * 10000 : 24 * 100);
      timeChange = (parseInt(this.scheduleSelectedClone.time.slice(0, 2)) + 24).toString() + this.scheduleSelectedClone.time.slice(2);
    }
    if (timeRow <= timeInt) {
      this.handleShowMessage('error', 'time-grey-out');
      return;
    }
    for (const header in this.scheduleSelectedClone) {
      if (this.scheduleSelectedClone[header].length > 256) {
        this.handleShowMessage('error', 'max-length');
        return;
      }
    }
    this.scheduleSelectedClone.time = timeChange;
    this.scheduleSelectedClone.calculatedTime = this.scheduleSelectedClone.time; //set calcilatedTime = time -> #1143
    const indexTable = this.schedulesDaily.findIndex(e => e?.key == this.scheduleSelected.key); //indexTable is order of table. index load from s3
    this.schedulesDaily[indexTable] = this.scheduleSelectedClone;
    this.scheduleSelected = this.scheduleSelectedClone;
    this.schedulesDaily[indexTable].isEdit = true;
    if (this.schedulesDaily[indexTable].index >= 0) {
      if (Object.keys(this.scheduleEdit).length <= 2) {
        this.isEditRow = false;
        this.dataService.sendData(['isEditRow', this.isEditRow]);
        this.schedulesDaily[indexTable].isEdit = false;
        return;
      }
      const indexDelete = this.scheduleDeleteArray.findIndex(e => e?.key == this.scheduleEdit.key); //find index edit in array

      const indexEdit = this.scheduleEditArray.findIndex(e => e?.key == this.scheduleEdit.key); //find index edit in array
      if (indexEdit == -1) {
        this.scheduleEditArray.push(this.scheduleEdit);
      } else {
        this.scheduleEditArray[indexEdit] = { ...this.scheduleEditArray[indexEdit], ...this.scheduleEdit };
      }
      if (indexDelete != -1) {
        this.scheduleDeleteArray[indexDelete] = { ...this.scheduleDeleteArray[indexDelete], ...this.scheduleEdit };
      }
      if (!this.scheduleSelected.itemEdit) {
        this.scheduleSelected.itemEdit = ',';
      }
      for (const key in this.scheduleEdit) {
        this.scheduleSelected.itemEdit += key + ',';
      }
      this.scheduleOperationManagerService.listScheduleOperationFromAPI.entry[this.scheduleSelected.key - 1] = this.scheduleSelected;
      this.isEditRow = false;
      this.dataService.sendData(['isEditRow', this.isEditRow]);
      if (this.scheduleEdit && this.scheduleEdit.time) {
        if (timeChange) {
          this.scheduleEdit.time = timeChange;
        }
        this.scheduleEdit.calculatedTime = this.scheduleEdit.time; //set calcilatedTime = time -> #1143
        this.sortWithTimeEdit(this.scheduleSelected.index);
      }
    } else {
      this.scheduleSelected.isFirstEdit = false;
      const indexEdit = this.scheduleInsertArray.findIndex(e => e?.key == this.scheduleSelected.key); //find index edit in array
      if (indexEdit == -1) {
        this.scheduleInsertArray.push(this.scheduleSelected);
        this.isEditRow = false;
        this.dataService.sendData(['isEditRow', this.isEditRow]);
        this.pushDataAddToList(this.scheduleSelected);
        return;
      } else {
        this.scheduleInsertArray[indexEdit] = this.scheduleSelected;
        this.scheduleOperationManagerService.listScheduleOperationFromAPI.entry[this.scheduleSelected.key - 1] = this.scheduleSelected;
        this.isEditRow = false;
        this.dataService.sendData(['isEditRow', this.isEditRow]);
        this.sortWithTimeEdit(this.scheduleSelected.index);
      }
    }
  }

  /**
   * cancel edit item
   */
  public cancelEditItem(): void {
    this.isEditRow = false;
    this.nameDisplayClone = undefined;
    this.scheduleSelectedClone = null;
    let indexTable = this.schedulesDaily.findIndex(e => e?.key == this.scheduleSelected.key); //indexTable is order of table. index load from s3
    this.dataService.sendData(['isEditRow', this.isEditRow]);
    if (this.scheduleSelected.index < 0 && this.schedulesDaily[indexTable].isFirstEdit) {
      this.schedulesDaily.splice(indexTable, 1);
      this.scheduleSelected = null;
      this.numRowInsert++;
    } else {
      // this.scheduleSelected = this.scheduleSelectedClone;
      // this.schedulesDaily[indexTable] = this.scheduleSelectedClone;
    }
    this.sendDataEnableEdit();
  }

  /**
   * insert row
   */
  private insertRow(): void {
    let indexTable;
    if (this.isEditRow) {
      return;
    }
    if (!this.scheduleSelected) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('dialog-error.title'),
          text: this.translateService.instant('schedule-operation-manager.choose-row')
        }
      });
      return;
    } else {
      indexTable = this.schedulesDaily.findIndex(e => e?.key == this.scheduleSelected.key);
    }

    this.isActiveTabCapture = false;
    const objAdd: any = {};
    let itemEdit = ',';
    for (const header of this.headerCRUDs) {
      objAdd[header.field] = '';
      itemEdit += header.field + ',';
    }
    objAdd['key'] = this.numRowInsert;
    objAdd['index'] = this.numRowInsert--;
    objAdd['itemEdit'] = itemEdit;
    objAdd['isFirstEdit'] = true;
    objAdd['isEditSuspension'] = true;
    objAdd['isEditHold'] = true;
    objAdd['isEditArrive'] = true;
    objAdd['isEditPriority'] = true;
    objAdd['isEdit'] = true;
    this.pushDataToListView(objAdd, indexTable);
    this.isEditRow = true;
    this.dataService.sendData(['isEditRow', this.isEditRow]);
  }

  /**
   * edit row
   */
  private editRow(): void {
    if (!this.scheduleSelected) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('dialog-error.title'),
          text: this.translateService.instant('schedule-operation-manager.choose-row')
        }
      });
      return;
    }
    this.isActiveTabCapture = false;
    this.scheduleEdit = { index: this.scheduleSelected.index, key: this.scheduleSelected.key };
    this.scheduleSelectedClone = _.cloneDeep(this.scheduleSelected);
    if (
      this.scheduleSelectedClone &&
      this.scheduleSelectedClone.time.length > 3 &&
      ((this.scheduleSelectedClone.time.length < 8 && this.haveTimess) || (this.scheduleSelectedClone.time.length < 5 && !this.haveTimess))
    ) {
      this.scheduleSelectedClone.time = '0' + this.scheduleSelectedClone.time;
    }
    this.isEditRow = true;
    this.dataService.sendData(['isEditRow', this.isEditRow]);
  }

  /**
   * on change checkbox item
   * @param type
   * @param i
   */
  onChangeCheckboxItem(type, i) {
    if (!this.checkRealTime(this.schedulesDaily[i]) && !'hold, arrive'.includes(type)) {
      return;
    }
    if (!this.checkEnableEdit(i)) {
      return;
    }
    if (this.schedulesDaily[i].delete || this.schedulesDaily[i].isDelete) {
      return;
    }
    if (this.schedulesDaily[i].isEdit) {
      return;
    }
    if (
      ['hold', 'arrive', 'priority'].includes(type) &&
      (this.schedulesDaily[i].suspension || this.schedulesDaily[i].suspention || this.schedulesDaily[i].isEditSuspension)
    ) {
      return;
    }

    if (
      'suspension'.includes(type) &&
      (this.schedulesDaily[i].arrive ||
        this.schedulesDaily[i].hold ||
        this.schedulesDaily[i].priority ||
        this.schedulesDaily[i].isEditArrive ||
        this.schedulesDaily[i].isEditPriority ||
        this.schedulesDaily[i].isEditHold)
    ) {
      return;
    }
    this.scheduleSelected = this.schedulesDaily[i];
    this.scheduleSelectedClone = this.scheduleSelected;
    if (type == 'hold' && this.scheduleSelected.arrive) {
      return;
    }
    if (type == 'hold' && !this.scheduleSelected.arrive && this.scheduleSelected.isEditArrive) {
      return;
    }
    if (
      this.scheduleSelectedClone &&
      this.scheduleSelectedClone.time.length > 3 &&
      ((this.scheduleSelectedClone.time.length < 8 && this.haveTimess) || (this.scheduleSelectedClone.time.length < 5 && !this.haveTimess))
    ) {
      this.scheduleSelectedClone.time = '0' + this.scheduleSelectedClone.time;
    }
    this.sendDataEnableEdit();
    if (this.scheduleSelected.index >= 0) {
      const indexEdit = this.scheduleEditArray.findIndex(e => e?.key == this.scheduleSelected.key); //find index edit in array
      if (indexEdit == -1) {
        let scheduleEdit: any = { index: this.scheduleSelected.index, key: this.scheduleSelected.key };
        if (type === 'hold') {
          scheduleEdit.hold = this.scheduleSelected.hold;
          this.scheduleSelected.isEditHold = true;
        } else if (type == 'arrive') {
          if (this.scheduleSelected.hold && this.scheduleSelected.arrive) {
            this.schedulesDaily[i].hold = !this.schedulesDaily[i].hold;
            scheduleEdit.hold = false;
            this.scheduleSelected.isEditHold = this.scheduleSelected.hold == this.schedulesDaily[i].hold;
          }
          scheduleEdit.arrive = this.scheduleSelected.arrive;
          this.scheduleSelected.isEditArrive = true;
        } else if (type == 'priority') {
          scheduleEdit.priority = this.scheduleSelected.priority;
          this.scheduleSelected.isEditPriority = true;
        } else if (type == 'suspension') {
          scheduleEdit.suspension = this.scheduleSelected.suspension;
          this.scheduleSelected.isEditSuspension = true;
        }
        this.scheduleEditArray.push(scheduleEdit);
      } else {
        if (type === 'hold') {
          if (this.scheduleEditArray[indexEdit].hold == undefined) {
            this.scheduleEditArray[indexEdit].hold = this.scheduleSelected.hold;
            this.scheduleSelected.isEditHold = true;
          } else {
            delete this.scheduleEditArray[indexEdit].hold;
            this.scheduleSelected.isEditHold = false;
          }
        } else if (type == 'arrive') {
          if (this.scheduleEditArray[indexEdit].arrive == undefined) {
            this.scheduleEditArray[indexEdit].arrive = this.scheduleSelected.arrive;
            this.scheduleSelected.isEditArrive = true;
            if (this.scheduleSelected.hold && this.scheduleSelected.arrive) {
              this.schedulesDaily[i].hold = !this.schedulesDaily[i].hold;
              if (this.scheduleSelected.isEditHold) {
                this.scheduleSelected.isEditHold = false;
                delete this.scheduleEditArray[indexEdit].hold;
              } else {
                this.scheduleSelected.isEditHold = true;
                this.scheduleEditArray[indexEdit].hold = this.schedulesDaily[i].hold;
              }
            }
          } else {
            delete this.scheduleEditArray[indexEdit].arrive;
            this.scheduleSelected.isEditArrive = false;
          }
        } else if (type == 'priority') {
          if (this.scheduleEditArray[indexEdit].priority == undefined) {
            this.scheduleEditArray[indexEdit].priority = this.scheduleSelected.priority;
            this.scheduleSelected.isEditPriority = true;
          } else {
            delete this.scheduleEditArray[indexEdit].priority;
            this.scheduleSelected.isEditPriority = false;
          }
        } else if (type == 'suspension') {
          if (this.scheduleEditArray[indexEdit].suspension == undefined) {
            this.scheduleEditArray[indexEdit].suspension = this.scheduleSelected.suspension;
            this.scheduleSelected.isEditSuspension = true;
          } else {
            delete this.scheduleEditArray[indexEdit].suspension;
            this.scheduleSelected.isEditSuspension = false;
          }
        }
      }
      this.scheduleOperationManagerService.listScheduleOperationFromAPI.entry[this.scheduleSelected.key - 1] = this.scheduleSelected;
    } else {
      const indexEdit = this.scheduleInsertArray.findIndex(e => e?.key == this.scheduleSelected.key); //find index edit in array
      if (type === 'arrive' && this.scheduleSelected.hold) {
        this.scheduleSelected.hold = false;
      }
      this.scheduleInsertArray[indexEdit] = this.scheduleSelected;
      this.scheduleOperationManagerService.listScheduleOperationFromAPI.entry[this.scheduleSelected.key - 1] = this.scheduleSelected;
    }
    if (!this.checkRealTime(this.scheduleSelected)) {
      //if time out and check hold -> set schedule selected = null;
      this.scheduleSelected = null;
      this.sendDataEnableEdit();
    }
  }

  /**
   * delete row
   */
  private deleteRow(): void {
    if (!this.scheduleSelected) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('dialog-error.title'),
          text: this.translateService.instant('schedule-operation-manager.choose-row')
        }
      });
      return;
    }
    if (this.isEditRow) {
      return;
    }
    if (this.scheduleSelected.isDelete) {
      // undeleteRow
      this.dialogService.showDialog(
        DialogConfirmComponent,
        {
          data: {
            text: this.translateService.instant(`schedule-operation-manager.confirm-undelete`),
            button1: this.translateService.instant('timetable-editor.yes'),
            button2: this.translateService.instant('timetable-editor.btn-no')
          }
        },
        result => {
          if (!result) {
            return;
          }
          this.handleUndeleteTimetables();
        }
      );
    } else {
      // deleteRow
      this.dialogService.showDialog(
        DialogConfirmComponent,
        {
          data: {
            text: this.translateService.instant(`schedule-operation-manager.confirm-delete`),
            button1: this.translateService.instant('timetable-editor.yes'),
            button2: this.translateService.instant('timetable-editor.btn-no')
          }
        },
        result => {
          if (!result) {
            return;
          }
          this.handleDeleteTimetables();
        }
      );
    }
  }

  /**
   * handleDeleteTimetables
   */
  handleDeleteTimetables() {
    this.scheduleDeleteArray.push(this.scheduleSelected);
    this.scheduleSelected.isDelete = true;
    if (this.scheduleSelected.index < 0) {
      this.scheduleSelected.isDeleteRowInsert = true;
    }
    this.scheduleOperationManagerService.listScheduleOperationFromAPI.entry[this.scheduleSelected.key - 1] = this.scheduleSelected;
    this.scheduleSelected = null;
    this.sendDataEnableEdit();
  }

  /**
   * handleUndeleteTimetables
   */
  handleUndeleteTimetables() {
    if (this.scheduleSelected.isDelete) {
      const indexUndelete = this.scheduleDeleteArray.findIndex(e => e.index == this.scheduleSelected.index);
      if (indexUndelete != -1) {
        this.scheduleDeleteArray.splice(indexUndelete, 1);
      }
    }
    delete this.scheduleSelected.isDelete;
    this.scheduleOperationManagerService.listScheduleOperationFromAPI.entry[this.scheduleSelected.key - 1] = this.scheduleSelected;
    this.sendDataEnableEdit();
  }

  /**
   * duplicate row
   */
  private duplicateRow(): void {
    let indexTable;
    if (!this.scheduleSelected) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('dialog-error.title'),
          text: this.translateService.instant('schedule-operation-manager.choose-row')
        }
      });
      return;
    } else {
      indexTable = this.schedulesDaily.findIndex(e => e?.key == this.scheduleSelected.key);
    }
    if (this.isEditRow) {
      return;
    }
    this.isActiveTabCapture = false;
    let itemEdit = ',';
    for (const header of this.headerCRUDs) {
      itemEdit += header.field + ',';
    }
    const objAdd: any = { ...this.scheduleSelected };
    objAdd['key'] = this.numRowInsert;
    objAdd['index'] = this.numRowInsert--;
    objAdd['itemEdit'] = itemEdit;
    objAdd['isFirstEdit'] = true;
    objAdd['isEdit'] = true;
    objAdd['isEditSuspension'] = true;
    objAdd['isEditHold'] = true;
    objAdd['isEditArrive'] = true;
    objAdd['isEditPriority'] = true;
    delete objAdd.isDelete;
    delete objAdd.delete;
    delete objAdd.hold;
    delete objAdd.arrive;
    delete objAdd.suspension;
    delete objAdd.priority;
    this.pushDataToListView(objAdd, indexTable + 1);
    this.isEditRow = true;
    this.dataService.sendData(['isEditRow', this.isEditRow]);
  }

  /**
   * sendDataEnableEdit
   */
  sendDataEnableEdit() {
    let dataSend: any = this.scheduleSelected ? true : false;
    this.scheduleSelectedClone = JSON.parse(JSON.stringify(this.scheduleSelected));
    if (
      this.scheduleSelectedClone &&
      this.scheduleSelectedClone.time.length > 3 &&
      ((this.scheduleSelectedClone.time.length < 8 && this.haveTimess) || (this.scheduleSelectedClone.time.length < 5 && !this.haveTimess))
    ) {
      this.scheduleSelectedClone.time = '0' + this.scheduleSelectedClone.time;
    }
    if (this.scheduleSelected && this.scheduleSelected.isDelete) {
      dataSend = 'undelete';
    }
    if (this.scheduleSelected && this.scheduleSelected.delete) {
      dataSend = 'disableDelete';
    }
    this.dataService.sendData(['enableEdit', dataSend]);
  }

  /**
   * checkBeforeUpdate
   */
  checkBeforeUpdate(): void {
    const dateNow = new Date(Helper.getCurrentDateByTimeZone(this.commonObject));
    const now = moment(dateNow)
      .format(this.formatTime)
      .replace(/:?/g, '');
    const timeInt = parseInt(now) + (this.formatTime == 'HH:mm:ss' ? this.timeAdd * 10000 : this.timeAdd * 100);
    const scheduleArray = JSON.parse(JSON.stringify(this.scheduleInsertArray));
    const scheduleEditArray = JSON.parse(JSON.stringify(this.scheduleEditArray));
    const scheduleDeleteArray = JSON.parse(JSON.stringify(this.scheduleDeleteArray));
    let invalid3Min = false;
    let timeCheck3Min =
      parseInt(
        moment(dateNow)
          .add(3, 'minute')
          .format(this.formatTime)
          .replace(/:?/g, '')
      ) + (this.formatTime == 'HH:mm:ss' ? this.timeAdd * 10000 : this.timeAdd * 100);
    let timeCheck1Min =
      parseInt(
        moment(dateNow)
          .add(1, 'minute')
          .format(this.formatTime)
          .replace(/:?/g, '')
      ) + (this.formatTime == 'HH:mm:ss' ? this.timeAdd * 10000 : this.timeAdd * 100);
    for (const scheduleTmp of scheduleEditArray) {
      if (Object.keys(scheduleTmp).length > 2) {
        if (Object.keys(scheduleTmp).length == 3 && (scheduleTmp.hold != undefined || scheduleTmp.arrive != undefined)) {
          continue;
        } else if (Object.keys(scheduleTmp).length == 4 && scheduleTmp.hold != undefined && scheduleTmp.arrive != undefined) {
          continue;
        }
        scheduleArray.push(this.scheduleOperationManagerService.listScheduleOperationFromAPI.entry[scheduleTmp.key - 1]);
      }
    }
    scheduleArray.push(...scheduleDeleteArray);
    for (const item of scheduleArray) {
      if (item.index <= 0 && (item.isDelete || item.isDeleteRowInsert)) {
        continue;
      }
      const timeRow = parseInt(item.time.replace(/:?/g, ''));
      if (timeRow <= timeCheck1Min) {
        this.handleShowMessage('error', 'time-out');
        return;
      } else if (timeRow <= timeCheck3Min) {
        invalid3Min = true;
      }
    }
    if (invalid3Min) {
      this.dialogService.showDialog(
        DialogConfirmComponent,
        {
          data: {
            text: this.translateService.instant(`schedule-operation-manager.confirm-time`),
            button1: this.translateService.instant('timetable-editor.yes'),
            button2: this.translateService.instant('timetable-editor.btn-no')
          }
        },
        result => {
          if (!result) {
            return;
          } else {
            this.updateData();
          }
        }
      );
    } else {
      this.updateData();
    }
  }

  /**
   * update data
   */
  public updateData() {
    this.tenantName = this.commonObject.tenantName;
    let jsonAdd, jsonMod;
    if (this.scheduleInsertArray && this.scheduleInsertArray.length > 0) {
      jsonAdd = {
        update_type: 'add',
        added_entry: [],
        account: this.tenantName.toUpperCase(),
        area_group: this.areaName
      };
      const scheduleInsertArray = JSON.parse(JSON.stringify(this.scheduleInsertArray));
      for (const tmpInsert of scheduleInsertArray) {
        tmpInsert.suspention = tmpInsert.suspension;
        delete tmpInsert.suspension;
        delete tmpInsert.index;
        delete tmpInsert.key;
        delete tmpInsert.isEdit;
        delete tmpInsert.notIn35Min;
        delete tmpInsert.isFirstEdit;
        delete tmpInsert.checkTime;
        delete tmpInsert.isEditHold;
        delete tmpInsert.isEditArrive;
        delete tmpInsert.isEditPriority;
        delete tmpInsert.isEditSuspension;
        delete tmpInsert.itemEdit;
        delete tmpInsert.color;
        delete tmpInsert.blink;
        //add color
        if (!tmpInsert.isDelete && !tmpInsert.isDeleteRowInsert) {
          jsonAdd.added_entry.push(tmpInsert);
        }
      }
    }
    if (
      (this.scheduleEditArray && this.scheduleEditArray.length > 0) ||
      (this.scheduleDeleteArray && this.scheduleDeleteArray.length > 0)
    ) {
      jsonMod = {
        update_type: 'modify',
        modify_info: [],
        account: this.tenantName.toUpperCase(),
        area_group: this.areaName
      };
      const scheduleEditArray = JSON.parse(JSON.stringify(this.scheduleEditArray));
      for (const tmpEdit of scheduleEditArray) {
        if (Object.keys(tmpEdit).length > 2) {
          //edit color
          if (tmpEdit.suspension !== undefined) {
            tmpEdit.suspention = tmpEdit.suspension;
            delete tmpEdit.suspension;
          }
          delete tmpEdit.key;
          delete tmpEdit.notIn35Min;
          delete tmpEdit.checkTime;
          delete tmpEdit.itemEdit;
          jsonMod.modify_info.push(tmpEdit);
        }
      }
      for (const tmpDelete of this.scheduleDeleteArray) {
        if (tmpDelete.index > 0) {
          jsonMod.modify_info.push({ index: tmpDelete.index, delete: true });
        }
      }
    }
    forkJoin(
      [
        jsonAdd?.added_entry.length > 0 ? this.apiCustomerService.updateDataScheduleOperation(this.apiUpdateData, jsonAdd) : of({}),
        jsonMod?.modify_info.length > 0 ? this.apiCustomerService.updateDataScheduleOperation(this.apiUpdateData, jsonMod) : of({})
      ],
      (resAdd, resMod) => {
        return {
          resAdd: resAdd,
          resMod: resMod
        };
      }
    ).subscribe(
      data => {
        if (jsonAdd?.added_entry.length > 0 || jsonMod?.modify_info.length > 0) {
          this.reloadData(true);
        }
      },
      err => {
        this.dialogService.showDialog(DialogMessageComponent, {
          data: {
            title: this.translateService.instant('dialog-error.title'),
            text: this.translateService.instant('schedule-operation-manager.update-failed')
          }
        });
      }
    );
  }

  /**
   * convertViewData
   * @param data
   * @returns
   */
  convertViewData(data) {
    if (!data) {
      return '';
    }
    const dataArrTime = data.split(':');
    if (
      (((data.length == 8 || data.length == 7) && dataArrTime.length == 3) ||
        ((data.length == 5 || data.length == 4) && dataArrTime.length == 2)) &&
      !isNaN(Number(dataArrTime[0]))
    ) {
      let hh = parseInt(dataArrTime[0]) > 23 ? dataArrTime[0] - 24 : dataArrTime[0];
      hh = (hh + '').length == 1 ? '0' + hh : hh;
      return dataArrTime.length == 2 ? hh + ':' + dataArrTime[1] : hh + ':' + dataArrTime[1] + ':' + dataArrTime[2];
    } else {
      return data;
    }
  }

  /**
   * openDialogMessageOperation
   */
  openDialogMessageOperation() {
    this.dialogService.showDialog(DialogMessageOperationComponent, { data: {}, maxHeight: '90vh' }, result => {
      if (result == undefined) {
        return;
      }
    });
  }

  /**
   * hide
   * @param event
   */
  public hide(event): void {
    this.nameDisplayClone = undefined;
  }

  /**
   * edit row display
   * @param label
   */
  public editRowDisplay(label): void {
    if (!this.isEditRow) {
      return;
    }
    this.nameDisplayClone = label;
  }

  /**
   *
   * @param field
   */
  public onChangeFieldRow(field): void {
    if (this.scheduleSelectedClone.index >= 0) {
      this.scheduleEdit[field] = this.scheduleSelectedClone[field];
    }
  }

  /**
   * operationInformationSetting
   */
  private operationInformationSetting(): void {
    // open popup
    this.dialogService.showDialog(DialogOperationInformationSettingScheduleComponent, { data: {} }, result => {
      if (result) {
      }
    });
  }

  /**
   * open dynamic message
   */
  private async openDynamicMessage() {
    if (this.isEditDynamicMessageMode || this.isEditEmergencyMode) {
      return;
    }
    if (this.devices.length == 0) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('dialog-message.error-title'),
          text: this.translateService.instant('dialog-message.timetable-operation.choose-devices')
        }
      });
      return;
    }
    await this.getAllNotificationSequence();
    await this.getAllMedias();
    await this.getAllNoticeTexts();
    this.dynamicMessages = [];
    this.isEditDynamicMessageMode = true;
    this.updateStateItemOnMenu.next();
    const currentDate = this.getCurrentDateByTimeZone(false);
    this.dynamicMessageService
      .getDynamicMessageForMultiDeviceByDeviceIdSchedule(
        this.devices.map(device => device.id),
        currentDate
      )
      .subscribe(
        data => {
          this.dynamicMessages = this.handleDataDynamicMessage(data as any);
          let playlistText = this.playlistTexts?.map(e => e.id);
          let playlistMedia = this.playlistMedias?.map(e => e.id);
          this.dynamicMessages?.forEach(element => {
            if (element?.textArea) {
              if (element?.playlistTextId && !playlistText.includes(element.playlistTextId)) {
                element.playlistTextId = null;
              }
              if (element?.playlistTextIdUpdate && !playlistText.includes(element.playlistTextId)) {
                element.playlistTextIdUpdate = null;
                element.scheduleUpdate = null;
              }
            } else {
              if (element?.playlistMediaId && !playlistMedia.includes(element.playlistMediaId)) {
                element.playlistMediaId = null;
              }
              if (element?.playlistMediaIdUpdate && !playlistMedia.includes(element.playlistMediaIdUpdate)) {
                element.playlistMediaIdUpdate = null;
                element.scheduleUpdate = null;
              }
            }
          });

          this.handleShowColumnTextAndMedia();
          this.oldDynamicMessages = _.cloneDeep(this.dynamicMessages);
        },
        () => this.handleErrorFromApi()
      );
  }

  /**
   * handle error from Api
   *
   * @param error
   */
  private handleErrorFromApi() {
    this.unSubAPIPending();
    this.dialogService.showDialog(DialogMessageComponent, {
      data: {
        title: this.translateService.instant('dialog-message.error-title'),
        text: this.translateService.instant('dialog-message.error')
      }
    });
  }

  /**
   * Handle show hide column text and media
   */
  private handleShowColumnTextAndMedia(): void {
    this.isOnlyTextArea = this.dynamicMessages?.every(dynamicMessage => dynamicMessage.textArea != null);
    this.isOnlyPictureArea = this.dynamicMessages?.every(dynamicMessage => dynamicMessage.pictureArea != null);
  }

  /**
   *Handle data dynamic message
   *
   * @param dynamicMessagesData
   */
  private handleDataDynamicMessage(dynamicMessagesData: DynamicMessageSchedule[]): DynamicMessageSchedule[] {
    return dynamicMessagesData
      .map(dynamicMessageData => {
        return this.convertDataDynamicMessageFromServer(dynamicMessageData);
      })
      .sort(
        (dv1, dv2) =>
          +dv1.device.id - +dv2.device.id || +dv1.textArea?.id - +dv2.textArea?.id || +dv1.pictureArea?.id - +dv2.pictureArea?.id
      );
  }

  /**
   * convert data dynamic message
   *
   * @param dynamicMessageData
   * @returns
   */
  private convertDataDynamicMessageFromServer(dynamicMessageData: any) {
    if (!dynamicMessageData) {
      return;
    }
    let dynamicMessage = new DynamicMessageSchedule();
    dynamicMessage.id = dynamicMessageData['id'];
    dynamicMessage.device = this.devices.find(device => device.id == dynamicMessageData['deviceId']);
    if (dynamicMessageData['textArea']) {
      dynamicMessage.textArea = Helper.convertDataTextArea(dynamicMessageData['textArea']);
    }
    if (dynamicMessageData['pictureArea']) {
      dynamicMessage.pictureArea = Helper.convertDataPictureArea(dynamicMessageData['pictureArea']);
    }
    dynamicMessage.playlistTextId = dynamicMessageData['playlistTextId'];
    dynamicMessage.playlistTextIdUpdate = dynamicMessageData['playlistTextIdUpdate'];
    dynamicMessage.playlistMediaId = dynamicMessageData['playlistMediaId'];
    dynamicMessage.playlistMediaIdUpdate = dynamicMessageData['playlistMediaIdUpdate'];
    dynamicMessage.scheduleUpdate = dynamicMessageData['scheduleUpdate'];

    return dynamicMessage;
  }

  /**
   * Get current date by time zone
   *
   * @param isGetHours
   * @returns
   */
  private getCurrentDateByTimeZone(isGetHours: boolean): string {
    var offsetHour = 0;
    var offsetMinute = 0;
    var setting = this.commonObject.setting;
    if (setting) {
      offsetHour = setting.timezone.offsetHour;
      offsetMinute = setting.timezone.offsetMinute;
    }
    return moment
      .utc()
      .add(offsetHour, 'hour')
      .add(offsetMinute, 'minute')
      .format(isGetHours ? Constant.FORMAT_HOURS : Constant.FORMAT_DATE);
  }

  /**
   * change dynamic message by enter key
   *
   * @param dynamicMessage
   * @param index
   * @param $event
   * @returns
   */
  public changeDynamicMessageEnterKey(dynamicMessage: DynamicMessage, index: number, $event: any) {
    if (!$event) {
      $event.preventDefault();
      return;
    }
    // key enter
    if ($event.keyCode == Constant.ENTER_KEY_CODE) {
      if (dynamicMessage.message.length > Constant.MAX_LENGTH_VALUE) {
        this.propertiesName.dynamicMessage = this.translateService.instant('dialog-message.timetable-operation.dynamic-message');
        this.dialogService.showDialog(
          DialogMessageComponent,
          {
            data: {
              title: this.translateService.instant('dialog-message.error-title'),
              text: Helper.getErrorMessage(
                ErrorEnum.MAX_LENGTH,
                this.propertiesName.dynamicMessage,
                Constant.MAX_LENGTH_VALUE,
                this.translateService
              )
            },
            autoFocus: false
          },
          () => {
            document.getElementById(`dynamicMessageInput${index}`).focus();
          }
        );
        return;
      }
      dynamicMessage.isEdit = false;
    }
  }

  /**
   * change dynamic message by keyboard
   *
   * @param index
   */
  public changeDynamicMessage(index: number): void {
    this.dynamicMessages[index].isChanged = this.oldDynamicMessages[index].message != this.dynamicMessages[index].message;
  }

  /**
   * open emergency
   */
  private openEmergency() {
    if (this.isEditDynamicMessageMode || this.isEditEmergencyMode) {
      return;
    }
    if (this.devices.length <= 0) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('dialog-message.error-title'),
          text: this.translateService.instant('dialog-message.timetable-operation.choose-devices')
        }
      });
      return;
    }
    this.openDialogEmergencyMode();
  }

  /**
   * open popup emergency mode
   */
  private openDialogEmergencyMode() {
    this.dialogService.showDialog(DialogEmergencyModeComponent, {}, result => {
      // click Cancel
      if (result.length == 0) {
        return;
      }
      // click Off
      if (!result) {
        this.updateEmergencyModeForDevices(this.devices, false);
        return;
      }
      // click Edit
      this.isEditEmergencyMode = true;
      this.updateStateItemOnMenu.next();
      this.emergencyDataService.getEmergencyScheduleData().subscribe(
        data => {
          if (data.length == 0) {
            this.emergencyData = new EmergencyData(Constant.EMPTY, -1, Constant.EMPTY);
            this.emergencyMediaIdOld = this.emergencyData.emergencyImageId;
            return;
          }
          this.emergencyData = data[0];
          if (this.emergencyData.emergencyImageId == -1 || !this.emergencyData.emergencyImageId || !this.emergencyData.media) {
            this.emergencyData.media = null;
            this.emergencyData.urlImage = Constant.EMPTY;
          }
          this.emergencyMediaIdOld = this.emergencyData.emergencyImageId;
        },
        () => this.handleErrorFromApi()
      );
    });
  }

  /**
   * update emergency mode for devices
   *
   * @param checkedDevices
   * @param isOnEmergencyMode
   */
  private updateEmergencyModeForDevices(checkedDevices: DeviceOperation[], isOnEmergencyMode: boolean) {
    const registrationIds = checkedDevices.map(device => device.registrationId);
    if (isOnEmergencyMode) {
      if (!this.emergencyData.emergencyImageId) {
        this.mediaService.uploadEmergencyMedia(this.emergencyData.media as any).subscribe(
          mediaResponse => {
            if (!mediaResponse) {
              this.handleErrorFromApi();
              return;
            }
            if (mediaResponse.id == Constant.ERROR_CODE_MAX_LENGTH_NAME) {
              this.dialogService.showDialog(DialogMessageComponent, {
                data: {
                  title: this.translateService.instant('dialog-message.error-title'),
                  text: Helper.formatString(
                    this.translateService.instant('timetable-operation-manager.message.length-file'),
                    `${Constant.MAX_LENGTH_MEDIA_NAME}`
                  )
                }
              });
              return;
            }
            this.emergencyData.media = mediaResponse;
            this.emergencyData.emergencyImageId = mediaResponse.id as number;
            this.emergencyData.urlImage = mediaResponse.url;
            this.emergencyMediaIdOld = this.emergencyData.emergencyImageId;
            this.handleDataEmergency(registrationIds);
          },
          error => this.handleErrorFromCustomerApi()
        );
      } else {
        // delete old emergency image after clear in GUI
        if (this.emergencyMediaIdOld && this.emergencyMediaIdOld != -1 && this.emergencyMediaIdOld != this.emergencyData.emergencyImageId) {
          this.mediaService.deleteEmergencyImage(this.emergencyMediaIdOld).toPromise();
        }
        this.handleDataEmergency(registrationIds);
      }
    } else {
      const payload = {
        id: registrationIds,
        timeoutSec: Constant.DEFAULT_TIMEOUT
      };
      this.deviceService.offEmergencyMode(payload).subscribe(
        async () => {
          this.showDialogSuccess('schedule-operation-manager.emergency-off');
        },
        error => {
          this.dialogService.showDialog(DialogMessageComponent, {
            data: {
              title: this.translateService.instant('dialog-error.title'),
              text: this.translateService.instant('dialog-error.msg')
            }
          });
        }
      );
    }
  }

  /**
   * Get devices success for delivery
   *
   * @param devices
   * @param isDynamicMessage
   * @param payloadData
   * @returns
   */
  private async getDevicesSuccessForDelivery(devices: Array<any>, isDynamicMessage: boolean, payloadData?: any): Promise<any> {
    let devicesSave = [];
    if (!devices) {
      return devicesSave;
    }
    await Promise.all(
      devices.map(async checkedDevice => {
        const payloadRealtime = {
          device: isDynamicMessage ? checkedDevice.registrationId : checkedDevice
        };
        let dataPayload;
        if (payloadData) {
          dataPayload = isDynamicMessage
            ? [...payloadData['dynamic_message_list']].find(data => data['device'] == payloadRealtime.device)['dynamic_message']
            : payloadData;
        }
        try {
          await this.handleCallApiCustomer(payloadRealtime, checkedDevice, dataPayload).then(data => {
            if (data) {
              devicesSave.push(data);
            }
          });
        } catch (error) {
          //@ts-ignore
        }
      })
    );
    return devicesSave;
  }

  /**
   * handle call api customer
   * @param payloadRealtime
   * @param checkedDeviceSave
   * @param payloadData
   * @returns
   */
  async handleCallApiCustomer(payloadRealtime, checkedDeviceSave, payloadData?: any) {
    try {
      return await this.asyncInterval(payloadRealtime, checkedDeviceSave, payloadData);
    } catch (error) {
      //@ts-ignore
    }
  }

  /**
   * Get data need check
   *
   * @param payloadData
   * @returns APINeedCheckEnum
   */
  private getDataNeedCheck(payloadData: any): APIEnum {
    if (!payloadData) {
      return APIEnum.OFF_EMERGENCY;
    }
    if (Array.isArray(payloadData)) {
      return APIEnum.DYNAMIC_MESSAGE;
    }
    return APIEnum.ON_EMERGENCY;
  }

  /**
   * async interval
   * @param payloadRealtime
   * @param checkedDeviceSave
   * @param payloadData
   * @param triesCallAPI
   * @returns
   */
  async asyncInterval(payloadRealtime, checkedDeviceSave, payloadData?: any, triesCallAPI = Constant.NUMBERS_OF_API_CALLS) {
    return new Promise((resolve, reject) => {
      let checkNum = this.getDataNeedCheck(payloadData);
      const interval = setInterval(async () => {
        this.apiCustomerService
          .getTimetableRealtimeStatus(payloadRealtime)
          .pipe(takeUntil(this.ngUnsubscribe))
          .subscribe(
            realtimeData => {
              switch (checkNum) {
                case APIEnum.OFF_EMERGENCY:
                  if (
                    realtimeData &&
                    !realtimeData[Constant.EMERGENCY_MESSAGE][Constant.IMAGE_ATTRIBUTE] &&
                    !realtimeData[Constant.EMERGENCY_MESSAGE][Constant.MESSAGE_ATTRIBUTE]
                  ) {
                    resolve(checkedDeviceSave);
                    clearInterval(interval);
                  }
                  break;
                case APIEnum.DYNAMIC_MESSAGE:
                  if (realtimeData && _.isEqual(realtimeData[Constant.DYNAMIC_MESSAGE], payloadData)) {
                    resolve(checkedDeviceSave);
                    clearInterval(interval);
                  }
                  break;
                case APIEnum.ON_EMERGENCY:
                  if (
                    realtimeData &&
                    realtimeData[Constant.EMERGENCY_MESSAGE][Constant.IMAGE_ATTRIBUTE] == payloadData[Constant.IMAGE_ATTRIBUTE] &&
                    realtimeData[Constant.EMERGENCY_MESSAGE][Constant.MESSAGE_ATTRIBUTE] == payloadData[Constant.MESSAGE_ATTRIBUTE]
                  ) {
                    resolve(checkedDeviceSave);
                    clearInterval(interval);
                  }
                  break;
                default:
                  break;
              }
              if (triesCallAPI <= 1) {
                reject();
                resolve(undefined);
                clearInterval(interval);
                this.executingService.executed();
              }
              triesCallAPI--;
            },
            () => {
              // call api error
              triesCallAPI--;
              if (triesCallAPI <= 0) {
                reject();
                resolve(undefined);
                clearInterval(interval);
                this.executingService.executed();
              } else {
                this.executingService.executing();
              }
            }
          );
      }, Constant.NUMBER_MILLISECONDS_CALL_API);
    });
  }

  /**
   * handle error from customer api
   *
   * @param errorApi
   * @param error
   */
  private handleErrorFromCustomerApi() {
    this.unSubAPIPending();
    this.dialogService.showDialog(DialogMessageComponent, {
      data: {
        title: this.translateService.instant('dialog-message.error-title'),
        text: this.translateService.instant('dialog-message.error')
      }
    });
  }

  /**
   * Handle data emergency
   *
   * @param registrationIds
   */
  private handleDataEmergency(registrationIds: string[]): void {
    this.mediaService.getMediaUrlToDelivery(this.emergencyData.media ? this.emergencyData.media.id : -1).subscribe(
      dataResponse => {
        const payload = {
          id: registrationIds,
          message: Helper.encodeHTML(this.emergencyData.emergencyText),
          image: dataResponse['url'],
          timeoutSec: Constant.DEFAULT_TIMEOUT,
          req_id: uniqueId()
        };
        if (this.emergencyData.emergencyText == '' && payload[Constant.IMAGE_ATTRIBUTE] == '') {
          this.dialogService.showDialog(DialogMessageComponent, {
            data: {
              title: this.translateService.instant('dialog-message.error-title'),
              text: this.translateService.instant('dialog-message.timetable-operation.text-image-not-empty')
            }
          });
          return;
        }
        this.deviceService.onEmergencyMode(payload).subscribe(
          async () => {
            this.saveEmergency();
          },
          error => this.handleErrorFromCustomerApi()
        );
      },
      error => this.handleErrorFromCustomerApi()
    );
  }

  /**
   * showDialogSuccess
   * @param msg
   */
  private showDialogSuccess(msg: any) {
    this.unSubAPIPending();
    this.dialogService.showDialog(DialogMessageComponent, {
      data: {
        title: this.translateService.instant('dialog-confirm.title'),
        text: this.translateService.instant(msg)
      }
    });
  }

  /**
   * unSubAPIPending
   */
  private unSubAPIPending(): void {
    this.ngUnsubscribe.next();
    // This completes the subject properlly.
    this.ngUnsubscribe.complete();
  }

  /**
   * update emergency mode
   *
   * @param registrationIds
   * @param isOnEmergency
   * @returns
   */
  private updateEmergencyMode(registrationIds: string[], isOnEmergency: boolean) {
    if (!registrationIds || registrationIds.length == 0) {
      return;
    }
    this.deviceService.updateEmergencyMode(registrationIds, isOnEmergency).subscribe(
      deviceIds => {
        if (!isOnEmergency) {
          this.executingService.executed();
        }
        deviceIds.forEach(deviceId => {
          this.devices.find(device => device.id == deviceId).isOnEmergency = isOnEmergency;
        });
      },
      () => this.handleErrorFromApi()
    );
  }

  /**
   * save emergency data
   */
  private saveEmergency() {
    let emergencyUpdate = new EmergencyData(this.emergencyData.emergencyText, this.emergencyData.emergencyImageId);
    emergencyUpdate.id = this.emergencyData.id;
    this.emergencyDataService.saveSchedule(emergencyUpdate).subscribe(
      data => {
        this.emergencyData.id = data.id;
        this.emergencyMediaIdOld = this.emergencyData.emergencyImageId;
        this.showDialogSuccess('schedule-operation-manager.emergency-on');
      },
      () => this.handleErrorFromApi()
    );
  }

  /**
   * allow drop
   *
   * @param e
   */
  public allowDrop(e) {
    e.preventDefault();
  }

  /**
   * drop picture for emergency data
   *
   * @param $event
   * @returns
   */
  public dropPictureForEmergency($event: any) {
    if (
      $event.dataTransfer.getData(Constant.MEDIA_VALUE) == '' ||
      JSON.parse($event.dataTransfer.getData(Constant.IS_MEDIA_IN_STATION_CONTENT_FOLDER)) ||
      JSON.parse($event.dataTransfer.getData(Constant.IS_MEDIA_IN_LCD_LAYOUT_EDITOR)) ||
      JSON.parse($event.dataTransfer.getData(Constant.FOLDER_INDEX_WORD_EDITOR))
    ) {
      return;
    }
    $event.preventDefault();
    var mediaObject = JSON.parse($event.dataTransfer.getData(Constant.MEDIA_VALUE));
    let media = Helper.convertMediaData(mediaObject);
    if (!Helper.isImage(media)) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('dialog-message.error-title'),
          text: this.translateService.instant('dialog-message.timetable-operation.invalid-file')
        }
      });
      return;
    }
    media.name = Helper.convertMediaNameBackWard(media.name, media.mediaNameEncode);
    this.emergencyData.media = media;
    this.emergencyData.urlImage = media.url;
    this.emergencyData.emergencyImageId = media.id;
  }

  /**
   * Drop media from pc to emergency
   *
   * @param mediaFile
   * @returns
   */
  public async dropMediaFromPCToEmergency(mediaFile: any) {
    if (!Helper.isImageFile(mediaFile[Constant.FILE_MEDIA_OBJECT])) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('dialog-message.error-title'),
          text: this.translateService.instant('dialog-message.timetable-operation.invalid-file')
        }
      });
      return;
    }

    // handle file drop
    let fileDrop = mediaFile[0][0];
    const type = mediaFile[Constant.FILE_MEDIA_OBJECT][Constant.TYPE_ATTRIBUTE];
    // file is pdf
    if (type.toLowerCase() == TypeMediaFileEnum.PDF) {
      const numberOfPage = await this.mediaService
        .getNumberOfPagePdf(new File([fileDrop], fileDrop[Constant.NAME_ATTRIBUTE]))
        .toPromise()
        .catch(error => {
          Helper.handleError(error, this.translateService, this.dialogService);
          return 0;
        });
      if (numberOfPage > 1) {
        this.dialogService.showDialog(
          DialogConfirmComponent,
          {
            data: {
              text: this.translateService.instant('dialog-confirm.timetable-operation-manager.confirm-convert-file-pdf'),
              button1: this.translateService.instant('dialog-confirm.timetable-operation-manager.button-1'),
              button2: this.translateService.instant('dialog-confirm.timetable-operation-manager.button-2')
            }
          },
          result => {
            if (!result) {
              return;
            }
            this.handleAfterDropMediaToEmergency(fileDrop, mediaFile);
          }
        );
      } else if (numberOfPage == 1) {
        this.handleAfterDropMediaToEmergency(fileDrop, mediaFile);
      }
    } else {
      this.handleAfterDropMediaToEmergency(fileDrop, mediaFile);
    }
  }

  /**
   * handle After Drop Media To Emergency
   * @param fileDrop
   * @param mediaFile
   * @returns
   */
  private async handleAfterDropMediaToEmergency(fileDrop: any, mediaFile: any): Promise<void> {
    let fileFromPC: Media = null;
    const type = mediaFile[Constant.FILE_MEDIA_OBJECT][Constant.TYPE_ATTRIBUTE];
    // file is pdf
    if (type.toLowerCase() == TypeMediaFileEnum.PDF) {
      let isErrorFonts = await this.mediaService
        .checkFontsConvert(new File([fileDrop], fileDrop[Constant.NAME_ATTRIBUTE]))
        .toPromise()
        .catch(error => {
          this.dialogService.showDialog(DialogMessageComponent, {
            data: {
              title: this.translateService.instant('index-word-editor.msg.title-error'),
              text: this.translateService.instant('lcd-layout-editor.msg.common-error')
            }
          });
          return null;
        });
      if (isErrorFonts) {
        this.dialogService.showDialog(
          DialogConfirmComponent,
          {
            data: {
              text: this.translateService.instant('lcd-layout-editor.msg.fonts-error-convert-pdf'),
              button1: this.translateService.instant('lcd-layout-editor.yes'),
              button2: this.translateService.instant('lcd-layout-editor.no')
            },
            autoFocus: false
          },
          async result => {
            if (!result) {
              return;
            }
            fileFromPC = await this.mediaService
              .convertPDFToImage(new File([fileDrop], fileDrop[Constant.NAME_ATTRIBUTE]), FolderNameDropPDFEnum.EMERGENCY)
              .toPromise()
              .catch(error => {
                this.dialogService.showDialog(DialogMessageComponent, {
                  data: {
                    title: this.translateService.instant('dialog-error.title'),
                    text: this.translateService.instant('schedule-operation-manager.pdf-cannot-display')
                  }
                });
                return null;
              });

            if (fileFromPC == null) {
              return;
            }
            this.showImgEmergencyAfterDrop(fileDrop, fileFromPC, mediaFile);
          }
        );
      } else {
        fileFromPC = await this.mediaService
          .convertPDFToImage(new File([fileDrop], fileDrop[Constant.NAME_ATTRIBUTE]), FolderNameDropPDFEnum.EMERGENCY)
          .toPromise()
          .catch(error => {
            this.dialogService.showDialog(DialogMessageComponent, {
              data: {
                title: this.translateService.instant('dialog-error.title'),
                text: this.translateService.instant('schedule-operation-manager.pdf-cannot-display')
              }
            });
            return null;
          });

        if (fileFromPC == null) {
          return;
        }
        this.showImgEmergencyAfterDrop(fileDrop, fileFromPC, mediaFile);
      }
    } else {
      this.showImgEmergencyAfterDrop(fileDrop, fileFromPC, mediaFile, true);
    }
  }

  /**
   * showImgEmergencyAfterDrop
   * @param fileDrop
   * @param fileFromPC
   * @param mediaFile
   * @returns
   */
  private async showImgEmergencyAfterDrop(fileDrop, fileFromPC, mediaFile, isCheck?) {
    let imageInfo = await this.getImageInformation(fileDrop);
    // validate unsupported file format
    if (isCheck && imageInfo[Constant.ERROR_ELEMENT]) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('dialog-message.error-title'),
          text: this.translateService.instant('dialog-error.unsupported-file-format')
        }
      });
      return;
    }
    this.emergencyData.emergencyImageId = undefined;
    this.emergencyData.urlImage = fileFromPC ? fileFromPC.url : mediaFile[Constant.FILE_MEDIA_OBJECT][Constant.URL_ATTRIBUTE];
    this.emergencyData.media = fileDrop;
    this.emergencyData.media.url = fileFromPC ? fileFromPC.url : mediaFile[Constant.FILE_MEDIA_OBJECT][Constant.URL_ATTRIBUTE];
  }

  /**
   * Drop media from pc
   *
   * @param file
   * @param dynamicMessage
   * @param indexDynamic
   */
  public async dropMediaFromPCDynamicMessage(file: any, dynamicMessage: DynamicMessage, indexDynamic: number): Promise<void> {
    if (dynamicMessage.textArea) {
      return;
    }
    if (!Helper.isImageFile(file[Constant.FILE_MEDIA_OBJECT])) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('dialog-message.error-title'),
          text: this.translateService.instant('dialog-message.timetable-operation.invalid-file')
        }
      });
      return;
    }
    // handle file drop
    let fileDrop = file[0][0];
    const mediaType = file[Constant.FILE_MEDIA_OBJECT][Constant.TYPE_ATTRIBUTE];
    const fileName = `${Constant.IMAGE_NAME_DROP_MEDIA}_${moment(new Date()).format(Constant.FORMAT_DATE_DROP_MEDIA)}.${mediaType}`;
    // file is pdf
    if (mediaType.toLowerCase() == TypeMediaFileEnum.PDF) {
      const numberOfPage = await this.mediaService
        .getNumberOfPagePdf(new File([fileDrop], fileDrop[Constant.NAME_ATTRIBUTE]))
        .toPromise()
        .catch(error => {
          Helper.handleError(error, this.translateService, this.dialogService);
          return 0;
        });
      if (numberOfPage > 1) {
        this.dialogService.showDialog(
          DialogConfirmComponent,
          {
            data: {
              text: this.translateService.instant('dialog-confirm.timetable-operation-manager.confirm-convert-file-pdf'),
              button1: this.translateService.instant('dialog-confirm.timetable-operation-manager.button-1'),
              button2: this.translateService.instant('dialog-confirm.timetable-operation-manager.button-2')
            }
          },
          result => {
            if (!result) {
              return;
            }
            this.handleAfterDropMediaToDynamicMessages(mediaType, fileDrop, fileName, dynamicMessage, file, indexDynamic);
          }
        );
      } else if (numberOfPage == 1) {
        this.handleAfterDropMediaToDynamicMessages(mediaType, fileDrop, fileName, dynamicMessage, file, indexDynamic);
      }
    } else {
      this.handleAfterDropMediaToDynamicMessages(mediaType, fileDrop, fileName, dynamicMessage, file, indexDynamic);
    }
  }

  /**
   * handle After Drop Media To DynamicMessages
   * @param mediaType
   * @param fileDrop
   * @param fileName
   * @param dynamicMessage
   * @param file
   * @param indexDynamic
   * @returns
   */
  private async handleAfterDropMediaToDynamicMessages(
    mediaType: any,
    fileDrop: any,
    fileName: any,
    dynamicMessage: DynamicMessage,
    file: any,
    indexDynamic: number
  ): Promise<void> {
    let fileFromPC: Media = null;
    // file is pdf
    if (mediaType.toLowerCase() == TypeMediaFileEnum.PDF) {
      let isErrorFonts = await this.mediaService
        .checkFontsConvert(new File([fileDrop], fileDrop[Constant.NAME_ATTRIBUTE]))
        .toPromise()
        .catch(error => {
          this.dialogService.showDialog(DialogMessageComponent, {
            data: {
              title: this.translateService.instant('index-word-editor.msg.title-error'),
              text: this.translateService.instant('lcd-layout-editor.msg.common-error')
            }
          });
          return null;
        });
      if (isErrorFonts) {
        this.dialogService.showDialog(
          DialogConfirmComponent,
          {
            data: {
              text: this.translateService.instant('lcd-layout-editor.msg.fonts-error-convert-pdf'),
              button1: this.translateService.instant('lcd-layout-editor.yes'),
              button2: this.translateService.instant('lcd-layout-editor.no')
            },
            autoFocus: false
          },
          async result => {
            if (!result) {
              return;
            }
            fileFromPC = await this.mediaService
              .convertPDFToImage(new File([fileDrop], fileName), FolderNameDropPDFEnum.DYNAMIC_MESSAGE)
              .toPromise()
              .catch(error => {
                this.dialogService.showDialog(DialogMessageComponent, {
                  data: {
                    title: this.translateService.instant('dialog-error.title'),
                    text: this.translateService.instant('schedule-operation-manager.pdf-cannot-display')
                  }
                });
                return null;
              });

            if (fileFromPC == null) {
              return;
            }
            this.showImgDynamicAfterDrop(fileDrop, dynamicMessage, fileName, fileFromPC, file, mediaType, indexDynamic);
          }
        );
      } else {
        fileFromPC = await this.mediaService
          .convertPDFToImage(new File([fileDrop], fileName), FolderNameDropPDFEnum.DYNAMIC_MESSAGE)
          .toPromise()
          .catch(error => {
            this.dialogService.showDialog(DialogMessageComponent, {
              data: {
                title: this.translateService.instant('dialog-error.title'),
                text: this.translateService.instant('schedule-operation-manager.pdf-cannot-display')
              }
            });
            return null;
          });

        if (fileFromPC == null) {
          return;
        }
        this.showImgDynamicAfterDrop(fileDrop, dynamicMessage, fileName, fileFromPC, file, mediaType, indexDynamic);
      }
    } else {
      this.showImgDynamicAfterDrop(fileDrop, dynamicMessage, fileName, fileFromPC, file, mediaType, indexDynamic, true);
    }
  }

  /**
   * showImgDynamicAfterDrop
   * @param fileDrop
   * @param dynamicMessage
   * @param fileName
   * @param fileFromPC
   * @param file
   * @param mediaType
   * @param indexDynamic
   * @returns
   */
  private async showImgDynamicAfterDrop(fileDrop, dynamicMessage, fileName, fileFromPC, file, mediaType, indexDynamic, isCheck?) {
    let imageInfo = await this.getImageInformation(fileDrop);
    // validate unsupported file format
    if (isCheck && imageInfo[Constant.ERROR_ELEMENT]) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('dialog-message.error-title'),
          text: this.translateService.instant('dialog-error.unsupported-file-format')
        }
      });
      return;
    }
    if (dynamicMessage.media && !dynamicMessage.uuidV4Image) {
      this.mediaIdsToDelete.push(dynamicMessage.media.id);
    }
    let uuid = uniqueId();
    const fileRenamed = new File([fileDrop], uuid);
    let mediaFile = new MediaFileDropped(uuid, fileName);
    const index = this.mediaFilesDroppedOperation?.findIndex(data => data?.uuidV4 == dynamicMessage.uuidV4Image);
    if (index == -1) {
      this.mediaFilesDroppedOperation.push(mediaFile);
      this.filesData.push(fileRenamed);
    } else {
      this.mediaFilesDroppedOperation[index] = mediaFile;
      this.filesData[index] = fileRenamed;
    }
    dynamicMessage.uuidV4Image = uuid;
    let mediaFromPC: Media = new ImageDynamicMessage();
    mediaFromPC.url = fileFromPC ? fileFromPC.url : file[Constant.FILE_MEDIA_OBJECT][Constant.URL_ATTRIBUTE];
    mediaFromPC.type = mediaType;
    dynamicMessage.media = mediaFromPC;
    dynamicMessage.isChanged = this.oldDynamicMessages[indexDynamic].media != dynamicMessage.media;
  }
  /**
   * Get image information
   *
   * @param media
   * @returns
   */
  private getImageInformation(media: any): any {
    return new Promise((resolve, reject) => {
      try {
        const fileReader = new FileReader();
        fileReader.onload = () => {
          const img = new Image();
          img.onload = () => {
            resolve({ width: img.width, height: img.height });
          };
          img.onerror = () => {
            resolve({ error: Constant.ERROR_ELEMENT });
          };
          img.src = fileReader.result as string;
        };
        fileReader.readAsDataURL(media);
      } catch (e) {
        reject(e);
      }
    });
  }

  /**
   * Clear emergency image
   */
  public clearEmergencyImage(): void {
    if (this.emergencyData.urlImage == '') {
      return;
    }
    this.dialogService.showDialog(
      DialogConfirmComponent,
      {
        data: {
          text: this.translateService.instant('dialog-confirm.timetable-operation-manager.want-to-clear-image'),
          button1: this.translateService.instant('dialog-confirm.timetable-operation-manager.button-1'),
          button2: this.translateService.instant('dialog-confirm.timetable-operation-manager.button-2')
        }
      },
      result => {
        if (result) {
          this.emergencyData.media = undefined;
          this.emergencyData.urlImage = '';
          this.emergencyData.emergencyImageId = -1;
        }
      }
    );
  }

  /**
   * edit dynamic message
   *
   * @param dynamicMessage
   */
  public editDynamicMessage(dynamicMessage: DynamicMessage) {
    if (dynamicMessage.pictureArea) {
      return;
    }
    dynamicMessage.isEdit = true;
    if (!dynamicMessage.message) {
      dynamicMessage.message = Constant.EMPTY;
    }
  }

  /**
   * Save dynamic messages
   *
   * @param dynamicMessages
   * @param devicesSave
   * @param isNotLeave
   */
  private saveDynamicMessages(dynamicMessages: Array<DynamicMessageSchedule>, devicesSave?: DeviceOperation[], isNotLeave?: boolean): void {
    this.handleSaveDynamicMessage(dynamicMessages, devicesSave, isNotLeave);
  }

  /**
   * handle save dynamic messages
   *
   * @param dynamicMessages
   * @param devicesSave
   * @param isNotLeave
   */
  private handleSaveDynamicMessage(dynamicMessages: DynamicMessageSchedule[], devicesSave: DeviceOperation[], isNotLeave?: boolean): void {
    const currentDate = this.getCurrentDateByTimeZone(false);
    let dynamicMessagesSave = dynamicMessages.map(dynamicMessage => {
      return {
        id: dynamicMessage.id,
        textArea: dynamicMessage.textArea,
        pictureArea: dynamicMessage.pictureArea,
        deviceId: dynamicMessage.device.id,
        playlistTextId: dynamicMessage?.playlistTextId,
        playlistTextIdUpdate: dynamicMessage?.playlistTextIdUpdate,
        playlistMediaId: dynamicMessage?.playlistMediaId,
        playlistMediaIdUpdate: dynamicMessage?.playlistMediaIdUpdate,
        scheduleUpdate: dynamicMessage?.scheduleUpdate
      };
    });
    if (devicesSave) {
      const checkedDevicesId = devicesSave.map(device => {
        return device.id;
      });
      dynamicMessagesSave = dynamicMessagesSave.filter(dynamicMessage => checkedDevicesId.find(id => +id == dynamicMessage.deviceId));
    }
    this.dynamicMessageService.saveDynamicMessagesSchedule(dynamicMessagesSave).subscribe(
      () => {
        if (!isNotLeave) {
          this.saveDataSuccess.emit(true);
          return;
        }
        this.dynamicMessageService
          .getDynamicMessageForMultiDeviceByDeviceIdSchedule(
            this.devices.map(device => device.id),
            currentDate
          )
          .subscribe(
            data => {
              this.executingService.executed();
              this.dynamicMessages = this.handleDataDynamicMessage(data as any);
              this.dataService.sendData([
                `${MODULE_NAME[FIELD_COMPONENT.ScheduleOperationManagerComponent]}:isSelectDynamicMessage`,
                false
              ]);
              this.oldDynamicMessages = _.cloneDeep(this.dynamicMessages);
              this.showDialogSuccess('schedule-operation-manager.dynamic-message-on');
            },
            () => {
              this.handleErrorFromApi();
            }
          );
        this.saveDataSuccess.emit(true);
      },
      () => {
        this.saveDataSuccess.emit(false);
        this.handleErrorFromApi();
      }
    );
  }

  /**
   * delivery
   */
  public delivery() {
    if (this.isEditDynamicMessageMode) {
      // delivery dynamic message
      if (this.dynamicMessages.length == 0) {
        this.dialogService.showDialog(DialogMessageComponent, {
          data: {
            title: this.translateService.instant('dialog-message.error-title'),
            text: this.translateService.instant('dialog-message.timetable-operation.no-dynamic-message')
          }
        });
        return;
      } else if (
        this.dynamicMessages.some(
          dynamicMessage =>
            (dynamicMessage?.playlistMediaIdUpdate || dynamicMessage?.playlistTextIdUpdate) && !dynamicMessage?.scheduleUpdate
        )
      ) {
        this.dialogService.showDialog(DialogMessageComponent, {
          data: {
            title: this.translateService.instant('dialog-message.error-title'),
            text: this.translateService.instant('schedule-operation-manager.schedule-update-is-not-selected')
          }
        });
        return;
      }
      this.handlingDynamicMessagingAPICalls();
    } else if (this.isEditEmergencyMode) {
      // delivery emergency mode
      if (this.emergencyData.emergencyText?.length > Constant.MAX_LENGTH_VALUE) {
        this.propertiesName.emergencyLinkText = this.translateService.instant('dialog-message.timetable-operation.link-text');
        this.dialogService.showDialog(DialogMessageComponent, {
          data: {
            title: this.translateService.instant('dialog-message.error-title'),
            text: Helper.getErrorMessage(
              ErrorEnum.MAX_LENGTH,
              this.propertiesName.emergencyLinkText,
              Constant.MAX_LENGTH_VALUE,
              this.translateService
            )
          }
        });
        return;
      }
      this.ngUnsubscribe = new Subject<void>();
      this.updateEmergencyModeForDevices(this.devices, true);
    }
  }

  /**
   * cancel
   */
  public cancel() {
    if (this.isEditDynamicMessageMode) {
      this.isEditDynamicMessageMode = false;
      this.dataService.sendData([`${MODULE_NAME[FIELD_COMPONENT.ScheduleOperationManagerComponent]}:isSelectDynamicMessage`, false]);
      this.dynamicMessages = [];
    } else if (this.isEditEmergencyMode) {
      this.isEditEmergencyMode = false;
    }
    this.updateStateItemOnMenu.next();
  }

  /**
   * getApiUpdateData
   */
  private getApiUpdateData(): void {
    this.scheduleOperationManagerService.getApiUpdate().subscribe(data => {
      this.apiUpdateData = data;
    });
  }

  /**
   * get information change date line
   */
  private getInformationScheduleOperation(): void {
    this.commonTableService.getValueCommonTableByKey(Constant.KEY_TIMETABLE_CHANGE_DATE_LINE_TIMETABLE).subscribe(
      data => {
        this.timeDateLine = data ? JSON.parse(data.value) : Constant.TIME_DATE_LINE_DEFAULT;
        this.getDataCurrentDay();
      },
      error => Helper.handleError(error, this.translateService, this.dialogService)
    );
  }

  /**
   * clearField
   */
  private clearField(): void {
    this.dynamicMessageSelected.media = undefined;
    this.dynamicMessageSelected.message = undefined;
    this.dynamicMessageSelected.isChanged = true;
    this.dynamicMessageSelected.playlistTextId = undefined;
    this.dynamicMessageSelected.playlistMediaId = undefined;
    this.dynamicMessageSelected.playlistTextIdUpdate = undefined;
    this.dynamicMessageSelected.playlistMediaIdUpdate = undefined;
    this.dynamicMessageSelected.scheduleUpdate = undefined;
  }

  /**
   * selectDynamicMessage
   * @param dynamicMessage
   */
  public selectDynamicMessage(dynamicMessage: DynamicMessageSchedule) {
    this.dynamicMessageSelected = dynamicMessage;
    this.dataService.sendData([`${MODULE_NAME[FIELD_COMPONENT.ScheduleOperationManagerComponent]}:isSelectDynamicMessage`, true]);
  }

  /**
   * checkEndColumn
   * @param column
   * @returns
   */
  public checkEndColumn(column: string) {
    const headerTopsActive = this.headerTopAPIs.filter(e => e.isActive);
    const index = headerTopsActive.findIndex(e => e.field == column);
    if (index != -1 && index == headerTopsActive.length - 1) {
      return true;
    } else {
      return false;
    }
  }

  /**
   * Get name app display
   * @param value nameApp
   * @returns
   */
  changeDisplay(value): String {
    if (value.length > 10) {
      value = value.substring(0, 10) + '...';
    }
    return value;
  }

  /**
   * checkChangeTextId
   * @param dynamicMessage
   */
  checkChangeDynamicMessage(dynamicMessage: any) {
    const index = this.oldDynamicMessages.findIndex(e => e.id == dynamicMessage.id);
    if (index == -1) {
      return;
    }
    if (dynamicMessage.textArea) {
      dynamicMessage.isChanged =
        this.oldDynamicMessages[index].playlistTextId != dynamicMessage.playlistTextId ||
        this.oldDynamicMessages[index].playlistTextIdUpdate != dynamicMessage.playlistTextIdUpdate ||
        this.oldDynamicMessages[index].scheduleUpdate != dynamicMessage.scheduleUpdate;
    } else {
      dynamicMessage.isChanged =
        this.oldDynamicMessages[index].playlistMediaId != dynamicMessage.playlistMediaId ||
        this.oldDynamicMessages[index].playlistMediaIdUpdate != dynamicMessage.playlistMediaIdUpdate ||
        this.oldDynamicMessages[index].scheduleUpdate != dynamicMessage.scheduleUpdate;
    }
  }

  /**
   * chooseTab
   * @param tab
   */
  async chooseTab(tabEnum: number): Promise<void> {
    this.isEnlargePreview = true;
    this.dataService.sendData([Constant.IS_IN_PREVIEW_AREA, false]);
    switch (tabEnum) {
      case Constant.OPERATION_SCHEDULE_ENUM: {
        let check = true;
        if (this.playlistSelected?.isEdit || !_.isEqual(this.sequenceOriginClone, this.sequenceOrigin)) {
          check = await this.confirmSaveBeforeLeave();
        }
        if (this.isEditText) {
          check = await this.confirmSaveTextBeforeLeave();
        }
        if (!check) {
          return;
        }
        this.tabSelected = tabEnum;
        this.dataService.sendData([Constant.IS_TAB_OPERATION_SCHEDULE, this.tabSelected == Constant.OPERATION_SCHEDULE_ENUM]);
        this.dataService.sendData([Constant.IS_TAB_NOTIFICATION_REGISTRATION, this.tabSelected == Constant.NOTIFICATION_REGISTRATION_ENUM]);
        this.playlistSelected = undefined;
        this.playlistGroupSelected = undefined;
        this.sequenceOrigin = [];
        this.sequenceOriginClone = [];
        this.sequenceDisplay = [];
        this.mediaSelected = undefined;
        this.noticeTextSelected = undefined;
        this.oldValuePlaylistName = '';
        this.searchInputValue = '';
        break;
      }
      case Constant.NOTIFICATION_REGISTRATION_ENUM: {
        //resetData
        this.tabSelected = tabEnum;
        this.isEnlargePreview = true;
        this.previewSelected = new NoticeText();
        this.previewSelectedClone = new NoticeText();
        this.dataService.sendData([Constant.IS_TAB_OPERATION_SCHEDULE, this.tabSelected == Constant.OPERATION_SCHEDULE_ENUM]);
        this.dataService.sendData([Constant.IS_TAB_NOTIFICATION_REGISTRATION, this.tabSelected == Constant.NOTIFICATION_REGISTRATION_ENUM]);
        this.dataService.sendData([`${MODULE_NAME[FIELD_COMPONENT.ScheduleOperationManagerComponent]}:isSelectDynamicMessage`, false]);
        this.dataService.sendData([`${MODULE_NAME[FIELD_COMPONENT.ScheduleOperationManagerComponent]}:isEditEmergencyMode`, false]);
        this.dataService.sendData([`${MODULE_NAME[FIELD_COMPONENT.ScheduleOperationManagerComponent]}:isEditDynamicMessageMode`, false]);
        this.isEditDynamicMessageMode = false;
        this.isEditEmergencyMode = false;
        await this.getAllNotificationSequence();
        await this.getNotificationMediaValidator();
        this.selectPlaylistGroup(this.groupPlaylistText);
        this.groupPlaylistText.isExpand = true;
        this.isEditText = false;
        break;
      }
    }
  }

  /**
   * getAllNotificationSequence
   */
  async getAllNotificationSequence(): Promise<any> {
    return new Promise<void>((resolve, reject) => {
      this.scheduleOperationManagerService.getAllNotificationSequence().subscribe(
        data => {
          if (!data || !data.length) {
            this.playlistTexts = [];
            this.playlistMedias = [];
            resolve();
            return;
          }
          data = data.sort((a, b) => a.id - b.id);
          this.playlistTexts = data.filter(e => e.group == 1);
          this.playlistMedias = data.filter(e => e.group == 2);
          resolve();
        },
        error => {
          this.playlistTexts = [];
          this.playlistMedias = [];
          reject();
        }
      );
    });
  }

  /**
   * importMediaFile
   */
  importMediaFile() {
    let element = document.getElementById(this.INPUT_IMPORT_ID) as HTMLInputElement;
    element.setAttribute('accept', '.jpg, .png, .bmp, .pdf');
    element.click();
  }

  async upload(event: any): Promise<void> {
    let selectedFiles: any[] = event.target.files;
    if (!selectedFiles || selectedFiles.length <= 0) {
      return;
    }

    this.errorMessageImportFile = await this.validateMediaInFrontEnd(selectedFiles);
    if (this.errorMessageImportFile.length > 0) {
      this.showImportError();
      return;
    }

    const pdfFiles = Array.from(selectedFiles).filter(file => file.type === 'application/pdf');
    if (pdfFiles.length) {
      let multiPagePdfFiles: File[] = [];

      for (const file of pdfFiles) {
        const isMultiPage = await this.isPdfMultiPage(file);
        if (isMultiPage) {
          multiPagePdfFiles.push(file);
        }
      }

      if (multiPagePdfFiles.length > 0) {
        this.dialogService.showDialog(
          DialogConfirmComponent,
          {
            data: {
              text: this.translateService.instant(`schedule-operation-manager.tab-notification-registration.msg.confirm-upload-1-pdf-page`),
              button1: this.translateService.instant('dialog-confirm.timetable-operation-manager.button-1'),
              button2: this.translateService.instant('dialog-confirm.timetable-operation-manager.button-2')
            }
          },
          async result => {
            if (!result) {
              this.refreshInputFileAndErrorMessage();
              return;
            }
            for (const file of selectedFiles) {
              await this.addNewNotificationMedia(file);
            }
            this.showImportError();
          }
        );
      } else {
        for (const file of selectedFiles) {
          await this.addNewNotificationMedia(file);
        }
        this.showImportError();
      }
    } else {
      for (const file of selectedFiles) {
        await this.addNewNotificationMedia(file);
      }
      this.showImportError();
    }
  }

  showImportError(): void {
    if (this.errorMessageImportFile.length > 0) {
      this.dialogService.showDialog(
        DialogSimpleSignageMessageComponent,
        {
          data: {
            title: this.translateService.instant('dialog-error.title'),
            texts: this.errorMessageImportFile
          }
        },
        result => {
          this.errorMessageImportFile = [];
        }
      );
    }
    this.resetInput();
  }

  private async addNewNotificationMedia(file: any): Promise<void> {
    await this.scheduleOperationManagerService
      .addNewNotificationMedia(file)
      .toPromise()
      .then(
        data => {
          if (!data) {
            this.errorMessageImportFile.push(
              Helper.formatString(
                this.translateService.instant(`schedule-operation-manager.tab-notification-registration.msg.other-error`),
                file[Constant.NAME_ELEMENT]
              )
            );
            return;
          }
          let errMessage = this.getErrorMessageFromBackWard(data, file);
          if (errMessage) {
            this.errorMessageImportFile.push(errMessage);
            return;
          }
          const media = Helper.convertDataNotificationMedia(data, true);
          this.medias.push(media);
          if (this.searchInputValue && this.medias.length) {
            this.mediasDisplay = _.cloneDeep(this.medias.filter(media => media.name.includes(this.searchInputValue)));
          } else {
            this.mediasDisplay = _.cloneDeep(this.medias);
          }
        },
        error => {
          if (error.status == Constant.NETWORK_ERROR_CODE) {
            if (!this.errorMessageImportFile.includes(this.translateService.instant('dialog-error.error-network'))) {
              this.errorMessageImportFile.push(this.translateService.instant('dialog-error.error-network'));
            }
            return;
          } else if (error.error?.detail == Constant.ERROR_EXISTS_NAME) {
            this.errorMessageImportFile.push(this.getErrorMessage('exists-name', file[Constant.NAME_ELEMENT], null));
            return;
          }
          this.errorMessageImportFile.push(
            Helper.formatString(
              this.translateService.instant(`schedule-operation-manager.tab-notification-registration.msg.other-error`),
              file[Constant.NAME_ELEMENT]
            )
          );
        }
      );
  }

  /**
   * refreshInputFileAndErrorMessage
   */
  private refreshInputFileAndErrorMessage(): void {
    // reset input file value
    let element = document.getElementById('importedFileNotificationRegistration') as HTMLInputElement;
    element.value = null;
    this.errorMessageImportFile = [];
  }

  /**
   * resetInput
   */
  private resetInput(): void {
    let element = document.getElementById('importedFileNotificationRegistration') as HTMLInputElement;
    element.value = null;
  }

  async validateMediaInFrontEnd(files: any[]): Promise<string[]> {
    let errMessages = [];
    const typeMedias = [TypeMediaFileEnum.JPG, TypeMediaFileEnum.PNG, TypeMediaFileEnum.BMP, TypeMediaFileEnum.PDF];
    for (const file of files) {
      let typeName = file.name.slice(file.name.lastIndexOf('.') + 1, file.name.length).toLowerCase();
      let fileName = file.name.slice(0, file.name.lastIndexOf('.'));
      // validate type file
      if (!typeMedias.includes(typeName)) {
        errMessages.push(
          Helper.formatString(
            this.translateService.instant('schedule-operation-manager.tab-notification-registration.msg.format-file'),
            file[Constant.NAME_ELEMENT]
          )
        );
        continue;
      }
      // validate special characters
      if (fileName.match(Constant.FORMAT) || fileName.includes('\\')) {
        errMessages.push(
          Helper.formatString(
            this.translateService.instant('schedule-operation-manager.tab-notification-registration.msg.special-character'),
            file[Constant.NAME_ELEMENT]
          )
        );
        continue;
      }
      // validate max length
      if (fileName.length > Constant.MAX_LENGTH_MEDIA_NAME) {
        errMessages.push(
          Helper.formatString(
            this.translateService.instant('schedule-operation-manager.tab-notification-registration.msg.length-file'),
            file[Constant.NAME_ELEMENT],
            `${Constant.MAX_LENGTH_MEDIA_NAME}`
          )
        );
        continue;
      }
      if (typeName != TypeMediaFileEnum.PDF) {
        // validate image size
        if (file[Constant.SIZE_ELEMENT] / Constant.SIZE_1MB > this.notificationMediaValidator.imageSize) {
          errMessages.push(
            this.getErrorMessage('image-max-size', file[Constant.NAME_ELEMENT], String(this.notificationMediaValidator.imageSize))
          );
          continue;
        }
        // get image information(w, h)
        let imageInfo = await this.getImageInformation(file);
        if (!imageInfo) {
          errMessages.push(
            Helper.formatString(this.translateService.instant('simple-signage-editor.other-error'), file[Constant.NAME_ELEMENT])
          );
          continue;
        }
        // validate unsupported file format
        if (imageInfo[Constant.ERROR_ELEMENT]) {
          errMessages.push(
            Helper.formatString(this.translateService.instant('dialog-error.unsupported-file-format'), file[Constant.NAME_ELEMENT])
          );
          continue;
        }
        // validate image height
        if (imageInfo[Constant.HEIGHT_ELEMENT] > this.notificationMediaValidator.imageHeight) {
          errMessages.push(
            this.getErrorMessage('image-max-height', file[Constant.NAME_ELEMENT], String(this.notificationMediaValidator.imageHeight))
          );
          continue;
        }
        // validate image width
        if (imageInfo[Constant.WIDTH_ELEMENT] > this.notificationMediaValidator.imageWidth) {
          errMessages.push(
            this.getErrorMessage('image-max-width', file[Constant.NAME_ELEMENT], String(this.notificationMediaValidator.imageWidth))
          );
          continue;
        }
      }
    }
    return errMessages;
  }

  private getErrorMessageFromBackWard(data: NotificationMedia, file: any): string {
    let result = null;
    switch (data.id) {
      case ErrorCodeNotificationMedia.ERROR_MAX_LENGTH_MEDIA_CODE:
        result = Helper.formatString(
          this.translateService.instant('schedule-operation-manager.tab-notification-registration.msg.length-file'),
          file[Constant.NAME_ELEMENT],
          `${Constant.MAX_LENGTH_MEDIA_NAME}`
        );
        break;
      case ErrorCodeNotificationMedia.ERROR_DUPLICATE_MEDIA_NAME:
        result = Helper.formatString(
          this.translateService.instant('schedule-operation-manager.tab-notification-registration.msg.errorr-duplicate-media-name'),
          file[Constant.NAME_ELEMENT],
          `${Constant.MAX_LENGTH_MEDIA_NAME}`
        );
        break;
      case ErrorCodeNotificationMedia.ERROR_OTHER_CODE:
        result = Helper.formatString(this.translateService.instant(`simple-signage-editor.other-error`), file[Constant.NAME_ELEMENT]);
        break;
      default:
        break;
    }
    return result;
  }

  /**
   * Get error message
   *
   * @param errorMessage
   * @param mediaName
   * @param errorValue
   * @returns
   */
  private getErrorMessage(errorMessage: string, mediaName: string, errorValue: string): string {
    return Helper.formatString(
      this.translateService.instant(`schedule-operation-manager.tab-notification-registration.msg.${errorMessage}`),
      mediaName,
      errorValue
    );
  }

  /**
   * get all medias
   */
  async getAllMedias(): Promise<void> {
    try {
      const data = await this.scheduleOperationManagerService.getAllMedia().toPromise();
      this.medias = Helper.convertDataNotificationMedias(data, true);
      if (this.searchInputValue && this.medias.length) {
        this.mediasDisplay = _.cloneDeep(this.medias.filter(media => media.name.includes(this.searchInputValue)));
      } else {
        this.mediasDisplay = _.cloneDeep(this.medias);
      }
      this.changeDetectorRef.detectChanges();
    } catch (error) {
      Helper.handleError(error, this.translateService, this.dialogService);
    }
  }

  /**
   * savePlaylist
   */
  savePlaylist(): void {
    this.isSaving = true;
    if (this.validatePlaylistName()) {
      this.isSaving = false;
      return;
    }
    const payload = {
      id: this.playlistSelected.id,
      name: this.playlistSelected.name,
      group: this.playlistSelected.group,
      sequence: JSON.stringify(
        this.sequenceOrigin.sort((a, b) => {
          return this.convertTimeToMinutes(a.startTime) - this.convertTimeToMinutes(b.startTime);
        })
      )
    };
    this.scheduleOperationManagerService.saveNotificationSequence(payload).subscribe(
      data => {
        if (!data) {
          this.isSaving = false;
          return;
        }
        if (data.error) {
          this.isSaving = false;
          this.dialogService.showDialog(DialogMessageComponent, {
            data: {
              title: this.translateService.instant('announcement-manager.error'),
              text: this.translateService.instant('announcement-manager.exists-pl')
            }
          });
          return;
        }
        this.playlistSelected.isEdit = false;
        const notificationSequences = data.notificationSequences.sort((a, b) => a.id - b.id);
        const notificationSequenceId = data.notificationSequenceId;
        this.sequenceOriginClone = _.cloneDeep(this.sequenceOrigin);
        if (!Helper.isEmpty(notificationSequenceId) && notificationSequences && notificationSequences.length) {
          this.playlistTexts = notificationSequences.filter(e => e.group == 1);
          this.playlistMedias = notificationSequences.filter(e => e.group == 2);
          const index = notificationSequences.findIndex(e => e.id == notificationSequenceId);
          if (index == -1) {
            return;
          }
          const group = notificationSequences[index].group;
          if (Helper.isEmpty(group)) {
            return;
          } else if (group == 1) {
            if (!this.playlistTexts || !this.playlistTexts.length) {
              return;
            }
            const indexPlaylistText = this.playlistTexts.findIndex(e => e.id == notificationSequenceId);
            if (indexPlaylistText == -1) {
              return;
            }
            this.groupPlaylistText.isExpand = true;
            this.selectPlaylist(this.playlistTexts[indexPlaylistText], true, this.sequenceSelected);
          } else {
            if (!this.playlistMedias || !this.playlistMedias.length) {
              return;
            }
            const indexplaylistMedia = this.playlistMedias.findIndex(e => e.id == notificationSequenceId);
            if (indexplaylistMedia == -1) {
              return;
            }
            this.groupPlaylistMedia.isExpand = true;
            this.selectPlaylist(this.playlistMedias[indexplaylistMedia], true, this.sequenceSelected);
          }
        }
        this.isSaving = false;
      },
      error => {
        this.isSaving = false;
        return;
      }
    );
  }

  /**
   * cancelAddNewSequence
   */
  cancelAddNewSequence(): void {
    this.playlistSelected.isEdit = false;
    this.dataService.sendData([Constant.IS_EDIT_PLAYLIST, this.playlistSelected.isEdit]);
    if (!this.playlistSelected.id) {
      let group = this.playlistSelected.group;
      if (group == 1) {
        this.playlistTexts.pop();
        if (this.playlistTexts?.length) {
          this.selectPlaylist(this.playlistTexts[0]);
        } else {
          this.playlistSelected = undefined;
          this.sequenceOrigin = [];
          this.sequenceOriginClone = [];
          this.sequenceDisplay = [];
        }
      } else {
        this.playlistMedias.pop();
        if (this.playlistMedias?.length) {
          this.selectPlaylist(this.playlistMedias[0]);
        } else {
          this.playlistSelected = undefined;
          this.sequenceOrigin = [];
          this.sequenceOriginClone = [];
          this.sequenceDisplay = [];
        }
      }
    } else {
      this.playlistSelected.name = this.oldValuePlaylistName;
      this.selectPlaylist(this.playlistSelected);
    }
  }

  /**
   * addNewSequence
   */
  addNewSequence(): void {
    if (this.playlistSelected?.isEdit) {
      return;
    }
    this.timelineDivision = this.TIMELINE_DIVISION_VALUE_60;
    this.timelineDivisionDisplay = this.TIMELINE_DIVISION_VALUE_60;
    this.countItem = 4;
    this.resetSequenceOrigin();
    this.resetSequenceDisplay();
    let group = this.playlistGroupSelected ? this.playlistGroupSelected.id : this.playlistSelected.group;
    let playlist = new SequenceNotification(null, '', group, true, this.sequenceOrigin);
    if (group == 1) {
      this.groupPlaylistText.isExpand = true;
      this.playlistTexts.push(playlist);
      this.playlistSelected = this.playlistTexts[this.playlistTexts.length - 1];
    } else {
      this.groupPlaylistMedia.isExpand = true;
      this.playlistMedias.push(playlist);
      this.playlistSelected = this.playlistMedias[this.playlistMedias.length - 1];
    }
    this.dataService.sendData([Constant.IS_EDIT_PLAYLIST, this.playlistSelected?.isEdit]);
  }

  /**
   * editNewSequence
   */
  editNewSequence() {
    if (this.playlistSelected?.isEdit) {
      return;
    }
    if (!this.playlistSelected) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('announcement-manager.error'),
          text: this.translateService.instant('announcement-manager.select-pl')
        }
      });
      return;
    }
    if (this.playlistSelected.group == 1) {
      if (!this.playlistTexts || !this.playlistTexts.length) {
        return;
      }
    } else {
      if (!this.playlistMedias || !this.playlistMedias.length) {
        return;
      }
    }
    this.playlistSelected.isEdit = true;
    this.oldValuePlaylistName = _.cloneDeep(this.playlistSelected.name);
    this.dataService.sendData([Constant.IS_EDIT_PLAYLIST, this.playlistSelected.isEdit]);
  }

  /**
   * duplicateNewSequence
   */
  duplicateNewSequence() {
    if (this.playlistSelected?.isEdit) {
      return;
    }
    if (!this.playlistSelected) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('announcement-manager.error'),
          text: this.translateService.instant('announcement-manager.select-pl')
        }
      });
      return;
    }
    let group = this.playlistSelected.group;
    let playlist = new SequenceNotification(null, this.playlistSelected.name, group, true, this.sequenceOrigin);
    if (group == 1) {
      this.groupPlaylistText.isExpand = true;
      this.playlistTexts.push(playlist);
      this.playlistSelected = this.playlistTexts[this.playlistTexts.length - 1];
    } else {
      this.groupPlaylistMedia.isExpand = true;
      this.playlistMedias.push(playlist);
      this.playlistSelected = this.playlistMedias[this.playlistMedias.length - 1];
    }
    this.dataService.sendData([Constant.IS_EDIT_PLAYLIST, this.playlistSelected?.isEdit]);
  }

  /**
   * editText
   */
  editText() {
    if (this.playlistSelected && this.playlistSelected.isEdit) {
      return;
    }
    if (!this.noticeTextSelected?.id) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('dialog-message.error-title'),
          text: this.translateService.instant(`schedule-operation-manager.tab-notification-registration.msg.please-choose-a-text`)
        }
      });
      return;
    }
    this.previewSelectedClone = _.cloneDeep(this.previewSelected);
    this.isEditText = true;
    this.quillComponent.focusEditor();
  }

  /**
   * deleteNewSequence
   */
  deleteNewSequence() {
    if (this.playlistSelected?.isEdit) {
      return;
    }
    if (!this.playlistSelected) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('announcement-manager.error'),
          text: this.translateService.instant('announcement-manager.select-pl')
        }
      });
      return;
    }
    this.dialogService.showDialog(
      DialogConfirmComponent,
      {
        data: {
          text: Helper.formatString(this.translateService.instant('announcement-manager.want-delete'), this.playlistSelected.name),
          button1: this.translateService.instant('announcement-manager.yes'),
          button2: this.translateService.instant('announcement-manager.no')
        }
      },
      result => {
        if (result) {
          const group = this.playlistSelected.group;
          this.scheduleOperationManagerService.deleteNotificationSequence(this.playlistSelected.id).subscribe(async data => {
            await this.getAllNotificationSequence();
            if (group == 1) {
              if (!this.playlistTexts || !this.playlistTexts.length) {
                return;
              }
              if (this.groupPlaylistText.isExpand) {
                this.groupPlaylistTextElement.nativeElement.scrollTop = 0;
              }
              this.selectPlaylist(this.playlistTexts[0]);
            } else {
              if (!this.playlistMedias || !this.playlistMedias.length) {
                return;
              }
              if (this.groupPlaylistMedia.isExpand) {
                this.groupPlaylistMediaElement.nativeElement.scrollTop = 0;
              }
              this.selectPlaylist(this.playlistMedias[0]);
            }
          });
        }
      }
    );
  }

  /**
   * timelineSetting
   */
  timelineSetting() {
    this.commonTableService.getValueCommonTableByKey(Constant.KEY_TIME_SETTING_NOTIFICATION).subscribe(
      data => {
        const commonTable = data ?? new CommonTable(Constant.KEY_TIME_SETTING_NOTIFICATION);
        this.dialogService.showDialog(
          DialogChangeTimelineSettingComponent,
          {
            data: { commonTable: commonTable, isTimeSetting: true }
          },
          result => {
            if (result) {
              this.timeSettingNotification = JSON.parse(result);
              this.updateSequenceFromTimeSetting();
            }
          }
        );
      },
      error => Helper.handleError(error, this.translateService, this.dialogService)
    );
  }

  /**
   * updateSequence
   */
  updateSequenceFromTimeSetting() {
    if (!this.timeSettingNotification) {
      return;
    }
    //reset sequenceOrigin from startTime = "00:00"
    this.sequenceOrigin = this.sequenceOrigin.sort((a, b) => {
      return this.convertTimeToMinutes(a.startTime) - this.convertTimeToMinutes(b.startTime);
    });
    const [hours] = this.timeSettingNotification.split(':').map(Number);
    this.sequenceOriginClone.push(...this.sequenceOriginClone.splice(0, hours * 4));
    this.sequenceOrigin.push(...this.sequenceOrigin.splice(0, hours * 4));
    if (!this.timelineDivision) {
      return;
    }
    switch (this.timelineDivision) {
      case this.TIMELINE_DIVISION_VALUE_60:
        this.resetSequenceDisplay();
        break;
      case this.TIMELINE_DIVISION_VALUE_30:
        this.sequenceDisplay = this.sequenceOrigin.map(e => e.value);
        this.timelineDivision = this.TIMELINE_DIVISION_VALUE_30;
        this.countItem = 2;
        this.updateTimelineZoomOut();
        break;
      case this.TIMELINE_DIVISION_VALUE_15:
        this.sequenceDisplay = this.sequenceOrigin.map(e => e.value);
    }
  }

  /**
   * expandPlaylistGroup
   * @param input
   * @returns
   */
  async expandPlaylistGroup(playlist: any): Promise<void> {
    if (!playlist) {
      return;
    }
    playlist.isExpand = !playlist.isExpand;
    if (playlist?.id == Constant.MEDIA && playlist?.isExpand) {
      await this.getAllMedias();
    }
  }

  /**
   * selectPlaylistGroup
   * @param playlistGroup
   */
  async selectPlaylistGroup(playlistGroup: any): Promise<void> {
    if (!playlistGroup) {
      this.playlistGroupSelected = undefined;
      return;
    }
    if (this.playlistSelected && this.playlistSelected.isEdit) {
      return;
    }
    let check = true;
    if (this.playlistSelected?.isEdit || !_.isEqual(this.sequenceOriginClone, this.sequenceOrigin)) {
      check = await this.confirmSaveBeforeLeave();
    }
    if (!check) {
      return;
    }
    if (this.playlistGroupSelected && this.playlistGroupSelected.id == playlistGroup.id) {
      return;
    }
    this.playlistSelected = undefined;
    this.sequenceOrigin = [];
    this.sequenceOriginClone = [];
    this.sequenceDisplay = [];
    this.playlistGroupSelected = playlistGroup;
    this.playlistGroupSelected.isExpand = true;
    if (this.playlistGroupSelected.id == 1) {
      await this.getAllNoticeTexts();
      this.isEnlargePreview = true;
    } else {
      await this.getAllMedias();
    }
    this.resetPreviewAreaDisplay();
  }

  /**
   * selectPlaylist
   * @param playlist
   * @returns
   */
  async selectPlaylist(playlist: any, isSave?: boolean, sequenceInput?: any, isSelectFromAnotherLocation?: boolean): Promise<void> {
    if (!playlist) {
      return;
    }
    if (this.playlistSelected && this.playlistSelected.isEdit) {
      return;
    }
    if (this.playlistSelected && this.playlistSelected.id == playlist.id && !isSave) {
      return;
    }
    let check = true;
    if (this.playlistSelected?.isEdit || !_.isEqual(this.sequenceOriginClone, this.sequenceOrigin)) {
      check = await this.confirmSaveBeforeLeave();
    }
    if (this.isEditText) {
      check = await this.confirmSaveTextBeforeLeave();
    }
    if (!check) {
      return;
    }
    this.playlistGroupSelected = undefined;
    let index;
    const group = playlist.group;
    if (group == 1) {
      this.isEnlargePreview = true;
      index = this.playlistTexts.findIndex(e => e.id == playlist.id);
      if (index != -1) {
        this.playlistSelected = this.playlistTexts[index];
        if (isSave && index == this.playlistTexts.length - 1) {
          this.changeDetectorRef.detectChanges();
          const element = this.groupPlaylistTextElement.nativeElement;
          element.scrollTop = element.scrollHeight - element.clientHeight;
        }
      }
    } else {
      index = this.playlistMedias.findIndex(e => e.id == playlist.id);
      if (index != -1) {
        this.playlistSelected = this.playlistMedias[index];
        if (isSave && index == this.playlistMedias.length - 1) {
          this.changeDetectorRef.detectChanges();
          const element = this.groupPlaylistMediaElement.nativeElement;
          element.scrollTop = element.scrollHeight - element.clientHeight;
        }
      }
    }
    this.scheduleOperationManagerService.getNotificationSequence(playlist.id).subscribe(async data => {
      if (!data) {
        this.playlistSelected = undefined;
        await this.getAllNotificationSequence();
        if (group == 1) {
          if (!this.playlistTexts || !this.playlistTexts.length) {
            return;
          }
          if (this.groupPlaylistText.isExpand) {
            this.groupPlaylistTextElement.nativeElement.scrollTop = 0;
          }
          this.selectPlaylist(this.playlistTexts[0]);
        } else {
          if (!this.playlistMedias || !this.playlistMedias.length) {
            return;
          }
          if (this.groupPlaylistMedia.isExpand) {
            this.groupPlaylistMediaElement.nativeElement.scrollTop = 0;
          }
          this.selectPlaylist(this.playlistMedias[0]);
        }
        return;
      }
      this.playlistSelected.sequence = data.sequence;
      this.playlistSelected.isEdit = false;
      if (!isSave) {
        this.timelineDivision = this.TIMELINE_DIVISION_VALUE_60;
        this.timelineDivisionDisplay = this.TIMELINE_DIVISION_VALUE_60;
        this.countItem = 4;
      }
      //set sequenceDisplay
      if (!data.sequence) {
        this.resetSequenceOrigin();
        this.resetPreviewAreaDisplay();
      } else {
        const sequence = JSON.parse(data.sequence);
        this.sequenceOrigin = sequence;
        this.sequenceOriginClone = _.cloneDeep(this.sequenceOrigin);
        this.updateSequenceFromTimeSetting();
        if (!isSelectFromAnotherLocation) {
          let typeSelected =
            this.playlistGroupSelected?.id == Constant.TEXT || this.playlistSelected.group == Constant.TEXT
              ? 'text'
              : this.playlistGroupSelected?.id == Constant.MEDIA || this.playlistSelected.group == Constant.MEDIA
              ? 'media'
              : null;

          if (!sequenceInput) {
            let firstIndexMediaOrText = this.sequenceDisplay.findIndex(item => typeof item === 'number');
            let indexMedia =
              typeSelected == 'text'
                ? this.listNoticeText.findIndex(e => e.id == this.sequenceDisplay[firstIndexMediaOrText])
                : typeSelected == 'media'
                ? this.medias.findIndex(e => e.id == this.sequenceDisplay[firstIndexMediaOrText])
                : -1;

            if (Helper.isEmpty(indexMedia) || indexMedia == -1) {
              this.sequenceSelected = undefined;
              this.resetPreviewAreaDisplay();
            } else {
              if (typeSelected == 'text') {
                this.selectSequence(firstIndexMediaOrText, this.listNoticeText[indexMedia].id);
              } else if (typeSelected == 'media') {
                this.selectSequence(firstIndexMediaOrText, this.medias[indexMedia].id);
              }
            }
          } else {
            let indexMedia =
              typeSelected == 'text'
                ? this.listNoticeText.findIndex(e => e.id == this.sequenceDisplay[sequenceInput?.index])
                : typeSelected == 'media'
                ? this.medias.findIndex(e => e.id == this.sequenceDisplay[sequenceInput?.index])
                : -1;

            if (typeSelected == 'text') {
              this.selectSequence(sequenceInput?.index, this.listNoticeText[indexMedia].id);
            } else if (typeSelected == 'media') {
              this.selectSequence(sequenceInput?.index, this.medias[indexMedia].id);
            }
          }
        }
      }
      this.changeDetectorRef.detectChanges();
      this.sequenceScroll.nativeElement.scrollTop = 0;
    });
  }

  /**
   * isValidatePlaylistNameFormat
   * @returns
   */
  isValidatePlaylistNameFormat(input: string): boolean {
    if (!input) {
      return false;
    }
    const invalidChars = /[¥\/:*?"<>|]/;

    // Kiểm tra chuỗi có chứa ký tự không hợp lệ không
    return invalidChars.test(input);
  }

  /**
   * validatePlaylistName
   * @returns
   */
  validatePlaylistName(): boolean {
    if (!this.playlistSelected) {
      return true;
    }
    if (!this.playlistSelected.name.trim()) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('simple-signage-editor.error'),
          text: this.translateService.instant('simple-signage-editor.empty-pl')
        }
      });
      return true;
    }
    if (this.playlistSelected.name.length >= 49) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('simple-signage-editor.error'),
          text: this.translateService.instant('schedule-operation-manager.tab-notification-registration.msg.error-playlist-name-length')
        }
      });
      return true;
    }
    if (this.isValidatePlaylistNameFormat(this.playlistSelected.name)) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('simple-signage-editor.error'),
          text: this.translateService.instant('schedule-operation-manager.tab-notification-registration.msg.error-playlist-name-format')
        }
      });
      return true;
    }
    return false;
  }

  /**
   * resetSequenceOrigin
   */
  resetSequenceOrigin(): void {
    this.sequenceOrigin = [
      { startTime: '00:00', value: null },
      { startTime: '00:15', value: null },
      { startTime: '00:30', value: null },
      { startTime: '00:45', value: null },
      { startTime: '01:00', value: null },
      { startTime: '01:15', value: null },
      { startTime: '01:30', value: null },
      { startTime: '01:45', value: null },
      { startTime: '02:00', value: null },
      { startTime: '02:15', value: null },
      { startTime: '02:30', value: null },
      { startTime: '02:45', value: null },
      { startTime: '03:00', value: null },
      { startTime: '03:15', value: null },
      { startTime: '03:30', value: null },
      { startTime: '03:45', value: null },
      { startTime: '04:00', value: null },
      { startTime: '04:15', value: null },
      { startTime: '04:30', value: null },
      { startTime: '04:45', value: null },
      { startTime: '05:00', value: null },
      { startTime: '05:15', value: null },
      { startTime: '05:30', value: null },
      { startTime: '05:45', value: null },
      { startTime: '06:00', value: null },
      { startTime: '06:15', value: null },
      { startTime: '06:30', value: null },
      { startTime: '06:45', value: null },
      { startTime: '07:00', value: null },
      { startTime: '07:15', value: null },
      { startTime: '07:30', value: null },
      { startTime: '07:45', value: null },
      { startTime: '08:00', value: null },
      { startTime: '08:15', value: null },
      { startTime: '08:30', value: null },
      { startTime: '08:45', value: null },
      { startTime: '09:00', value: null },
      { startTime: '09:15', value: null },
      { startTime: '09:30', value: null },
      { startTime: '09:45', value: null },
      { startTime: '10:00', value: null },
      { startTime: '10:15', value: null },
      { startTime: '10:30', value: null },
      { startTime: '10:45', value: null },
      { startTime: '11:00', value: null },
      { startTime: '11:15', value: null },
      { startTime: '11:30', value: null },
      { startTime: '11:45', value: null },
      { startTime: '12:00', value: null },
      { startTime: '12:15', value: null },
      { startTime: '12:30', value: null },
      { startTime: '12:45', value: null },
      { startTime: '13:00', value: null },
      { startTime: '13:15', value: null },
      { startTime: '13:30', value: null },
      { startTime: '13:45', value: null },
      { startTime: '14:00', value: null },
      { startTime: '14:15', value: null },
      { startTime: '14:30', value: null },
      { startTime: '14:45', value: null },
      { startTime: '15:00', value: null },
      { startTime: '15:15', value: null },
      { startTime: '15:30', value: null },
      { startTime: '15:45', value: null },
      { startTime: '16:00', value: null },
      { startTime: '16:15', value: null },
      { startTime: '16:30', value: null },
      { startTime: '16:45', value: null },
      { startTime: '17:00', value: null },
      { startTime: '17:15', value: null },
      { startTime: '17:30', value: null },
      { startTime: '17:45', value: null },
      { startTime: '18:00', value: null },
      { startTime: '18:15', value: null },
      { startTime: '18:30', value: null },
      { startTime: '18:45', value: null },
      { startTime: '19:00', value: null },
      { startTime: '19:15', value: null },
      { startTime: '19:30', value: null },
      { startTime: '19:45', value: null },
      { startTime: '20:00', value: null },
      { startTime: '20:15', value: null },
      { startTime: '20:30', value: null },
      { startTime: '20:45', value: null },
      { startTime: '21:00', value: null },
      { startTime: '21:15', value: null },
      { startTime: '21:30', value: null },
      { startTime: '21:45', value: null },
      { startTime: '22:00', value: null },
      { startTime: '22:15', value: null },
      { startTime: '22:30', value: null },
      { startTime: '22:45', value: null },
      { startTime: '23:00', value: null },
      { startTime: '23:15', value: null },
      { startTime: '23:30', value: null },
      { startTime: '23:45', value: null }
    ];
    this.sequenceOriginClone = _.cloneDeep(this.sequenceOrigin);
  }

  /**
   * resetSequenceDisplay
   */
  resetSequenceDisplay(): void {
    this.sequenceDisplay = this.sequenceOrigin.map(e => e.value);
    this.timelineDivision = this.TIMELINE_DIVISION_VALUE_30;
    this.countItem = 2;
    this.updateTimelineZoomOut();
    this.timelineDivision = this.TIMELINE_DIVISION_VALUE_60;
    this.countItem = 4;
    this.updateTimelineZoomOut();
  }

  /**
   * zoomIn
   * @returns
   */
  zoomIn(): void {
    if (this.timelineDivisionDisplay == this.TIMELINE_DIVISION_VALUE_15) {
      return;
    }
    switch (this.timelineDivisionDisplay) {
      case this.TIMELINE_DIVISION_VALUE_60:
        this.timelineDivision = this.TIMELINE_DIVISION_VALUE_30;
        this.timelineDivisionDisplay = this.TIMELINE_DIVISION_VALUE_30;
        this.countItem = 2;
        break;
      case this.TIMELINE_DIVISION_VALUE_30:
        this.timelineDivision = this.TIMELINE_DIVISION_VALUE_15;
        this.timelineDivisionDisplay = this.TIMELINE_DIVISION_VALUE_15;
        this.countItem = 1;
        break;
      default:
        break;
    }
    // update time line
    this.updateTimelineZoomIn();
    this.resetPreviewAreaDisplay();
  }

  /**
   * zoomOut
   * @returns
   */
  zoomOut(): void {
    if (this.timelineDivisionDisplay == this.TIMELINE_DIVISION_VALUE_60) {
      return;
    }
    switch (this.timelineDivisionDisplay) {
      case this.TIMELINE_DIVISION_VALUE_30:
        this.dialogService.showDialog(
          DialogConfirmComponent,
          {
            data: {
              text: this.translateService.instant(`schedule-operation-manager.tab-notification-registration.msg.confirm-change-timeline`),
              button1: this.translateService.instant('dialog-change-template.yes'),
              button2: this.translateService.instant('dialog-change-template.no')
            }
          },
          result => {
            if (!result) {
              this.timelineDivisionDisplay = this.TIMELINE_DIVISION_VALUE_30;
              return;
            }
            this.timelineDivision = this.TIMELINE_DIVISION_VALUE_60;
            this.timelineDivisionDisplay = this.TIMELINE_DIVISION_VALUE_60;
            this.countItem = 4;
            // update time line
            this.updateTimelineZoomOut();
            this.resetPreviewAreaDisplay();
          }
        );
        break;
      case this.TIMELINE_DIVISION_VALUE_15:
        this.dialogService.showDialog(
          DialogConfirmComponent,
          {
            data: {
              text: this.translateService.instant(`schedule-operation-manager.tab-notification-registration.msg.confirm-change-timeline`),
              button1: this.translateService.instant('dialog-change-template.yes'),
              button2: this.translateService.instant('dialog-change-template.no')
            }
          },
          result => {
            if (!result) {
              this.timelineDivisionDisplay = this.TIMELINE_DIVISION_VALUE_15;
              return;
            }
            this.timelineDivision = this.TIMELINE_DIVISION_VALUE_30;
            this.timelineDivisionDisplay = this.TIMELINE_DIVISION_VALUE_30;
            this.countItem = 2;
            // update time line
            this.updateTimelineZoomOut();
            this.resetPreviewAreaDisplay();
          }
        );
        break;
      default:
        break;
    }
  }

  /**
   * updateTimeline
   * @param value
   */
  updateTimelineZoomIn(): void {
    if (Helper.isEmpty(this.timelineDivision)) {
      return;
    }
    if (this.timelineDivision == this.TIMELINE_DIVISION_VALUE_30) {
      this.sequenceDisplay = [];
      this.sequenceOrigin.forEach((_, index) => {
        if (index % 2 == 0) {
          if (this.sequenceOrigin[index].value) {
            this.sequenceDisplay.push(this.sequenceOrigin[index].value);
          } else {
            this.sequenceDisplay.push(this.sequenceOrigin[index + 1].value);
          }
        }
      });
    } else {
      this.sequenceDisplay = this.sequenceOrigin.map(e => e.value);
    }
  }

  /**
   * updateTimelineZoomOut
   * @returns
   */
  updateTimelineZoomOut(): void {
    if (Helper.isEmpty(this.timelineDivision)) {
      return;
    }
    this.sequenceDisplay.forEach((e, index) => {
      if (index % 2 == 0 && !e) {
        this.sequenceDisplay[index] = this.sequenceDisplay[index + 1];
      }
    });
    this.sequenceDisplay = this.sequenceDisplay.filter((_, index) => index % 2 === 0);
  }

  /**
   * convertTimeToMinutes
   * @param time
   * @returns
   */
  convertTimeToMinutes(time: any): number {
    // Tách giờ và phút từ chuỗi thời gian
    const [hours, minutes] = time.split(':').map(Number); // Chuyển thành số

    // Chuyển đổi thành phút
    return hours * 60 + minutes;
  }

  /**
   * convertMinutesToTime
   * @param minutes
   * @returns
   */
  convertMinutesToTime(minutes: number): string {
    // Lấy số giờ và phút từ tổng số phút
    const hours = Math.floor(minutes / 60);
    const mins = minutes % 60;

    // Định dạng giờ và phút để luôn có 2 chữ số
    const formattedHours = String(hours).padStart(2, '0');
    const formattedMinutes = String(mins).padStart(2, '0');

    // Kết hợp giờ và phút theo định dạng HH:mm
    return `${formattedHours}:${formattedMinutes}`;
  }

  /**
   * dropItem
   * @param event
   * @param i
   * @returns
   */
  dropItem(event: any) {
    if (!event) {
      return;
    }
    if (event.previousContainer !== event.container) {
      if (event.currentIndex > this.sequenceDisplay.length - 1) {
        return;
      }
      this.sequenceDisplay[event.currentIndex] = event?.item?.data;
      this.sequenceOrigin[event.currentIndex * this.countItem].value = event?.item?.data;
      if (this.countItem > 1) {
        for (let i = event.currentIndex * this.countItem + 1; i < event.currentIndex * this.countItem + this.countItem; i++) {
          this.sequenceOrigin[i].value = this.sequenceOrigin[i].value ?? event?.item?.data;
        }
      }
      this.selectSequence(event.currentIndex, event?.item?.data);
    } else {
      if (event.isPointerOverContainer) {
        moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
        this.sequenceOrigin.forEach(e => (e.value = null));
        event.container.data.forEach((e, index) => {
          for (let i = index * this.countItem; i < index * this.countItem + this.countItem; i++) {
            this.sequenceOrigin[i].value = e;
          }
        });
        this.selectSequence(event.currentIndex, event.container.data[event.currentIndex]);
      }
    }

    this.isDraggingMedia = false;
  }

  /**
   * dropMediaSequence
   * @param event
   * @param i
   * @returns
   */
  dropMediaSequence(event: any, i: any) {
    if (!event) {
      return;
    }
    if (event.previousContainer == event.container) {
      moveItemInArray(event.container.data, event.previousIndex, i);
    }
  }

  /**
   * preventDrop
   * @param e
   */
  preventDrop(e: any) {
    e.preventDefault();
  }

  /**
   * cancelSequence
   */
  cancelSequence() {
    this.sequenceOrigin = _.cloneDeep(this.sequenceOriginClone);
    // this.updateSequenceFromTimeSetting();
  }

  saveText() {}

  searchMediaByName(key: string) {
    let typeSelected =
      this.playlistGroupSelected?.id == Constant.TEXT || this.playlistSelected?.group == Constant.TEXT
        ? 'text'
        : this.playlistGroupSelected?.id == Constant.MEDIA || this.playlistSelected?.group == Constant.MEDIA
        ? 'media'
        : null;

    if (!typeSelected) {
      return;
    }
    if (typeSelected == 'text') {
      this.listNoticeTextDisplay = _.cloneDeep(this.listNoticeText.filter(text => text.label.includes(key)));
      this.selectNoticeText(this.listNoticeTextDisplay[0]);
    } else {
      this.mediasDisplay = _.cloneDeep(this.medias.filter(media => media.name.includes(key)));
      this.selectMedia(this.mediasDisplay[0]);
    }
  }

  clearAndSearchAll() {
    let typeSelected =
      this.playlistGroupSelected?.id == Constant.TEXT || this.playlistSelected?.group == Constant.TEXT
        ? 'text'
        : this.playlistGroupSelected?.id == Constant.MEDIA || this.playlistSelected?.group == Constant.MEDIA
        ? 'media'
        : null;

    this.searchInputValue = '';
    if (typeSelected == 'text') {
      this.listNoticeTextDisplay = _.cloneDeep(this.listNoticeText);
      this.selectNoticeText(this.listNoticeTextDisplay[0]);
    } else {
      this.mediasDisplay = _.cloneDeep(this.medias);
      this.selectMedia(this.mediasDisplay[0]);
    }
  }

  /**
   * deleteItem
   * @returns
   */
  async deleteItem() {
    if (this.playlistSelected && this.playlistSelected.isEdit) {
      return;
    }
    if (this.sequenceSelected) {
      this.sequenceDisplay[this.sequenceSelected.index] = null;
      this.sequenceOrigin[this.sequenceSelected.index * this.countItem].value = null;
      if (this.countItem > 1) {
        for (
          let i = this.sequenceSelected.index * this.countItem + 1;
          i < this.sequenceSelected.index * this.countItem + this.countItem;
          i++
        ) {
          this.sequenceOrigin[i].value = null;
        }
      }
      this.sequenceSelected = null;
      this.resetPreviewAreaDisplay();
    } else {
      let typeSelected =
        this.playlistGroupSelected?.id == Constant.TEXT || this.playlistSelected?.group == Constant.TEXT
          ? 'text'
          : this.playlistGroupSelected?.id == Constant.MEDIA || this.playlistSelected?.group == Constant.MEDIA
          ? 'media'
          : null;

      if (!typeSelected) {
        return;
      }

      switch (typeSelected) {
        case 'text':
          if (!this.noticeTextSelected?.id) {
            this.dialogService.showDialog(DialogMessageComponent, {
              data: {
                title: this.translateService.instant('dialog-message.error-title'),
                text: this.translateService.instant(`schedule-operation-manager.tab-notification-registration.msg.please-choose-a-text`)
              }
            });
            return;
          }

          this.dialogService.showDialog(
            DialogConfirmComponent,
            {
              data: {
                text: Helper.formatString(
                  this.translateService.instant(`schedule-operation-manager.tab-notification-registration.msg.confirm-delete`),
                  this.noticeTextSelected.label
                ),
                button1: this.translateService.instant('dialog-confirm.timetable-operation-manager.button-1'),
                button2: this.translateService.instant('dialog-confirm.timetable-operation-manager.button-2')
              }
            },
            async result => {
              if (!result) {
                return;
              }
              try {
                let resultDelete = await this.scheduleOperationManagerService.deleteNoticeTextById(this.noticeTextSelected.id).toPromise();
                await this.getAllNoticeTexts();
                this.selectNoticeText(this.listNoticeTextDisplay[0]);
                if (this.listTextDisplay) {
                  this.listTextDisplay.nativeElement.scrollTop = 0;
                }
                if (resultDelete == 'Changes the playlist') {
                  if (this.playlistSelected) {
                    this.selectPlaylist(this.playlistSelected, null, null, true);
                  }
                }
              } catch (error) {
                Helper.handleError(error, this.translateService, this.dialogService);
              }
            }
          );
          break;
        case 'media':
          if (!this.mediaSelected) {
            this.dialogService.showDialog(DialogMessageComponent, {
              data: {
                title: this.translateService.instant('dialog-message.error-title'),
                text: this.translateService.instant(`schedule-operation-manager.tab-notification-registration.msg.please-choose-a-media`)
              }
            });
            return;
          }

          this.dialogService.showDialog(
            DialogConfirmComponent,
            {
              data: {
                text: Helper.formatString(
                  this.translateService.instant(`schedule-operation-manager.tab-notification-registration.msg.confirm-delete`),
                  `${this.mediaSelected?.name}${'.'}${this.mediaSelected?.type}`
                ),
                button1: this.translateService.instant('dialog-confirm.timetable-operation-manager.button-1'),
                button2: this.translateService.instant('dialog-confirm.timetable-operation-manager.button-2')
              }
            },
            async result => {
              if (!result) {
                return;
              }
              try {
                let resultDelete = await this.scheduleOperationManagerService.deleteMediaById(this.mediaSelected.id).toPromise();
                await this.getAllMedias();
                this.selectMedia(this.mediasDisplay[0]);
                if (this.listMediaDisplay) {
                  this.listMediaDisplay.nativeElement.scrollTop = 0;
                }
                if (resultDelete == 'Changes the playlist') {
                  if (this.playlistSelected) {
                    this.selectPlaylist(this.playlistSelected, null, null, true);
                  }
                }
              } catch (error) {
                Helper.handleError(error, this.translateService, this.dialogService);
              }
            }
          );
          break;
        default:
          break;
      }
    }
  }

  handleButtonBack() {}

  addFolder() {}

  /**
   * change display list folder/media
   * @param isTypeFolder
   */
  public changeDisplayListMedia(isTypeFolder: boolean): void {
    this.isTypeList = isTypeFolder;
  }

  dropMediaInFolderList(event: any) {}

  handleMouseOver(folder: any) {}

  handleMouseLeave(folder: any) {}

  selectFolder(folder: any) {}

  openFolderMedia(folder: any) {}

  saveFolder() {}

  cancelAddFolder() {}

  changeStartTimeAndDuration() {}

  selectMedia(media: any) {
    this.mediaSelected = null;
    if (!media) {
      return;
    }
    this.mediaSelected = media;
    this.resetPreviewAreaDisplay(Constant.MEDIA);
  }

  setDelta(delta: any): void {
    this.previewSelected.delta = delta;
  }

  handleContentSaved(content: any): void {
    this.scheduleOperationManagerService.saveNoticeText(content).subscribe(
      async res => {
        await this.getAllNoticeTexts();
        if (!this.listNoticeText?.length) {
          this.noticeTextSelected = new NoticeText();
        } else {
          if (!content?.id) {
            this.isEditText = false;
            const index = this.listNoticeTextDisplay.findIndex(text => text.text == content.text);
            if (index != -1) {
              this.selectNoticeText(this.listNoticeTextDisplay[index]);
            } else {
              this.selectNoticeText(this.listNoticeTextDisplay[0]);
            }
            this.previewSelectedClone = undefined;
            if (this.listTextDisplay) {
              this.listTextDisplay.nativeElement.scrollTop = this.listTextDisplay.nativeElement.scrollHeight;
            }
          } else {
            let index = this.listNoticeTextDisplay.findIndex(e => e.id == content.id);
            if (!Helper.isEmpty(index) && index != -1) {
              this.isEditText = false;
              if (index != -1) {
                this.selectNoticeText(this.listNoticeTextDisplay[index]);
              } else {
                this.selectNoticeText(this.listNoticeTextDisplay[0]);
              }
              if (this.playlistSelected) {
                this.selectPlaylist(this.playlistSelected, null, null, true);
              }
              this.previewSelectedClone = undefined;
            }
          }
        }
      },
      error => this.handleShowErrorMessageFromServerWhenSaved(error)
    );
  }

  handleShowErrorMessageFromServerWhenSaved(error: any) {
    let msg = this.translateService.instant('dialog-error.msg');
    if (error.error?.detail == Constant.ERROR_LIMIT_RECORD) {
      msg = this.translateService.instant('schedule-operation-manager.tab-notification-registration.msg.limit-record-notice-text');
    } else if (error.status == Constant.NETWORK_ERROR_CODE) {
      msg = this.translateService.instant('dialog-error.error-network');
    }
    this.dialogService.showDialog(DialogMessageComponent, {
      data: {
        title: this.translateService.instant('dialog-error.title'),
        text: msg
      }
    });
  }

  stringifyObj(obj: any): string {
    if (typeof obj === 'object' && obj !== null && !Array.isArray(obj)) {
      return JSON.stringify(obj);
    } else {
      return null;
    }
  }

  async getAllNoticeTexts(): Promise<void> {
    try {
      this.listNoticeText = await this.scheduleOperationManagerService.getAllNoticeTexts().toPromise();
      if (this.searchInputValue && this.listNoticeText.length) {
        this.listNoticeTextDisplay = _.cloneDeep(this.listNoticeText.filter(text => text.label.includes(this.searchInputValue)));
      } else {
        this.listNoticeTextDisplay = _.cloneDeep(this.listNoticeText);
      }
      this.changeDetectorRef.detectChanges();
    } catch (error) {
      Helper.handleError(error, this.translateService, this.dialogService);
    }
  }
  /**
   *
   * @param noticeText
   * @returns
   */
  async selectNoticeText(noticeText: NoticeText): Promise<void> {
    if (!noticeText) {
      return;
    }
    let isCheckChange = true;
    if (this.isEditText) {
      isCheckChange = await this.confirmSaveTextBeforeLeave();
    }
    if (!isCheckChange) {
      return;
    }
    this.noticeTextSelected = _.cloneDeep(noticeText);
    this.resetPreviewAreaDisplay(Constant.TEXT);
  }

  /**
   * confirmSaveTextBeforeLeave
   * @returns
   */
  private async confirmSaveTextBeforeLeave(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      return resolve(true);
      this.dialogService.showDialog(
        DialogConfirmComponent,
        {
          data: {
            text: this.translateService.instant('announcement-manager.save-changes'),
            button1: this.translateService.instant('announcement-manager.yes'),
            button2: this.translateService.instant('announcement-manager.no')
          }
        },
        async data => {
          if (!data) {
            return resolve(true);
          }
          const isPass = await this.saveContentBeforeLeave();
          if (isPass) {
            return resolve(true);
          } else {
            return resolve(false);
          }
        }
      );
    });
  }

  /**
   * saveContentBeforeLeave
   * @returns
   */
  saveContentBeforeLeave(): Promise<boolean> {
    return new Promise(async resolve => {
      if (!this.quillComponent.validateNoticeText()) {
        resolve(false);
        return;
      }
      this.quillComponent.saveText(true);
      try {
        await this.scheduleOperationManagerService.saveNoticeText(this.quillComponent.noticeTextData).toPromise();

        await this.getAllNoticeTexts();
        this.isEditText = false;
        resolve(true);
      } catch (error) {
        resolve(false);
      }
    });
  }

  /**
   * getendTime
   * @param index
   * @returns
   */
  getEndTime(index: number): string {
    if (Helper.isEmpty(index) || Helper.isEmpty(this.timelineDivision)) {
      return;
    }
    let minute = 0;
    switch (this.timelineDivision) {
      case this.TIMELINE_DIVISION_VALUE_60:
        minute = minute + (index + 1) * 60 + this.convertTimeToMinutes(this.timeSettingNotification);
        break;
      case this.TIMELINE_DIVISION_VALUE_30:
        minute = minute + (index + 1) * 30 + this.convertTimeToMinutes(this.timeSettingNotification);
        break;
      default:
        minute = minute + (index + 1) * 15 + this.convertTimeToMinutes(this.timeSettingNotification);
        break;
    }
    return this.convertMinutesToTime(minute);
  }

  /**
   * getLabel
   * @param id
   * @returns
   */
  getLabel(id: number) {
    if (Helper.isEmpty(id)) {
      return;
    }
    const index = this.listNoticeText.findIndex(e => e.id == id);
    if (index != -1) {
      return this.listNoticeText[index].label;
    }
  }

  /**
   * getText
   * @param id
   * @returns
   */
  getText(id: number) {
    if (Helper.isEmpty(id)) {
      return;
    }
    const index = this.listNoticeText.findIndex(e => e.id == id);
    if (index != -1) {
      return Helper.changeDisplay(this.listNoticeText[index].text, 210);
    }
  }

  /**
   * getImage
   * @param id
   * @returns
   */
  getImage(id: number) {
    if (Helper.isEmpty(id)) {
      return;
    }
    const index = this.medias.findIndex(e => e.id == id);
    if (index != -1) {
      return this.medias[index].url;
    }
  }

  /**
   * onMouseUp
   * @param event
   */
  onMouseUp(): void {
    switch (this.timelineDivision) {
      case this.TIMELINE_DIVISION_VALUE_60:
        //60 => 30
        if (this.timelineDivisionDisplay == this.TIMELINE_DIVISION_VALUE_30) {
          this.timelineDivision = this.TIMELINE_DIVISION_VALUE_30;
          this.countItem = 2;
          this.updateTimelineZoomIn();
        } else if (this.timelineDivisionDisplay == this.TIMELINE_DIVISION_VALUE_15) {
          //60 => 15
          this.timelineDivision = this.TIMELINE_DIVISION_VALUE_30;
          this.countItem = 2;
          this.updateTimelineZoomIn();
          this.timelineDivision = this.TIMELINE_DIVISION_VALUE_15;
          this.countItem = 1;
          this.updateTimelineZoomIn();
        }
        break;
      case this.TIMELINE_DIVISION_VALUE_30:
        //30 => 60
        if (this.timelineDivisionDisplay == this.TIMELINE_DIVISION_VALUE_60) {
          this.dialogService.showDialog(
            DialogConfirmComponent,
            {
              data: {
                text: this.translateService.instant(`schedule-operation-manager.tab-notification-registration.msg.confirm-change-timeline`),
                button1: this.translateService.instant('dialog-change-template.yes'),
                button2: this.translateService.instant('dialog-change-template.no')
              }
            },
            result => {
              if (!result) {
                this.timelineDivisionDisplay = this.TIMELINE_DIVISION_VALUE_30;
                return;
              }
              this.timelineDivision = this.TIMELINE_DIVISION_VALUE_60;
              this.countItem = 4;
              this.updateTimelineZoomOut();
            }
          );
        } else if (this.timelineDivisionDisplay == this.TIMELINE_DIVISION_VALUE_15) {
          //30 => 15
          this.timelineDivision = this.TIMELINE_DIVISION_VALUE_15;
          this.countItem = 1;
          this.updateTimelineZoomIn();
        }
        break;
      case this.TIMELINE_DIVISION_VALUE_15:
        //15 => 30
        if (this.timelineDivisionDisplay == this.TIMELINE_DIVISION_VALUE_30) {
          this.dialogService.showDialog(
            DialogConfirmComponent,
            {
              data: {
                text: this.translateService.instant(`schedule-operation-manager.tab-notification-registration.msg.confirm-change-timeline`),
                button1: this.translateService.instant('dialog-change-template.yes'),
                button2: this.translateService.instant('dialog-change-template.no')
              }
            },
            result => {
              if (!result) {
                this.timelineDivisionDisplay = this.TIMELINE_DIVISION_VALUE_15;
                return;
              }
              this.timelineDivision = this.TIMELINE_DIVISION_VALUE_30;
              this.countItem = 2;
              this.updateTimelineZoomOut();
            }
          );
        } else if (this.timelineDivisionDisplay == this.TIMELINE_DIVISION_VALUE_60) {
          //15 => 60
          this.dialogService.showDialog(
            DialogConfirmComponent,
            {
              data: {
                text: this.translateService.instant(`schedule-operation-manager.tab-notification-registration.msg.confirm-change-timeline`),
                button1: this.translateService.instant('dialog-change-template.yes'),
                button2: this.translateService.instant('dialog-change-template.no')
              }
            },
            result => {
              if (!result) {
                this.timelineDivisionDisplay = this.TIMELINE_DIVISION_VALUE_15;
                return;
              }
              this.resetSequenceDisplay();
            }
          );
        }
        break;
    }
    console.log(this.timelineDivision);
  }

  async selectSequence(index: any, idArea: any): Promise<void> {
    let check = true;
    if (this.isEditText) {
      check = await this.confirmSaveTextBeforeLeave();
    }
    if (!check) {
      return;
    }
    let idTextOrMedia = idArea && typeof idArea == 'number' ? idArea : null;
    this.sequenceSelected = {
      endtime: this.getEndTime(index),
      idMedia: idTextOrMedia,
      index: index
    };
    this.resetPreviewAreaDisplay(Constant.PREVIEW);
  }

  addNewNoticeText() {
    if (this.playlistSelected && this.playlistSelected.isEdit) {
      return;
    }
    this.noticeTextSelected = new NoticeText();
    this.resetPreviewAreaDisplay(Constant.TEXT);
    this.isEditText = true;
    this.previewSelectedClone = _.cloneDeep(this.previewSelected);
    this.quillComponent.focusEditor();
  }

  /**
   * isPdfMultiPage
   * @param file
   * @returns
   */
  private async isPdfMultiPage(file: File): Promise<boolean> {
    try {
      const numberOfPage = await this.mediaService.getNumberOfPagePdf(file).toPromise();
      return numberOfPage > 1;
    } catch (error) {
      Helper.handleError(error, this.translateService, this.dialogService);
    }
  }

  resetPreviewAreaDisplay(location?: number): void {
    let typeSelected =
      this.playlistGroupSelected?.id == Constant.TEXT || this.playlistSelected?.group == Constant.TEXT
        ? 'text'
        : this.playlistGroupSelected?.id == Constant.MEDIA || this.playlistSelected?.group == Constant.MEDIA
        ? 'media'
        : null;
    this.isEditText = false;
    switch (location) {
      case Constant.PREVIEW:
        this.noticeTextSelected = undefined;
        this.mediaSelected = undefined;
        if (!this.sequenceSelected?.idMedia) {
          this.previewSelected = typeSelected == 'text' ? new NoticeText() : typeSelected == 'media' ? new NotificationMedia() : undefined;
        } else {
          let index =
            typeSelected == 'text'
              ? this.listNoticeText.findIndex(e => e.id === this.sequenceSelected?.idMedia)
              : typeSelected == 'media'
              ? this.medias.findIndex(e => e.id === this.sequenceSelected?.idMedia)
              : -1;
          if (!Helper.isEmpty(index) && index != -1) {
            this.previewSelected =
              typeSelected == 'text'
                ? _.cloneDeep(this.listNoticeText[index])
                : typeSelected == 'media'
                ? _.cloneDeep(this.medias[index])
                : undefined;
          } else {
            this.previewSelected =
              typeSelected == 'text' ? new NoticeText() : typeSelected == 'media' ? new NotificationMedia() : undefined;
          }
        }
        this.dataService.sendData([Constant.IS_IN_PREVIEW_AREA, true]);
        this.changeDetectorRef.detectChanges();
        break;

      case Constant.TEXT:
        this.previewSelected = _.cloneDeep(this.noticeTextSelected);
        this.sequenceSelected = undefined;
        this.mediaSelected = undefined;
        this.dataService.sendData([Constant.IS_IN_PREVIEW_AREA, false]);
        this.changeDetectorRef.detectChanges();
        break;

      case Constant.MEDIA:
        this.previewSelected = _.cloneDeep(this.mediaSelected);
        this.sequenceSelected = undefined;
        this.noticeTextSelected = undefined;
        this.dataService.sendData([Constant.IS_IN_PREVIEW_AREA, false]);
        this.changeDetectorRef.detectChanges();
        break;

      default:
        this.previewSelected = typeSelected == 'text' ? new NoticeText() : typeSelected == 'media' ? new NotificationMedia() : undefined;
        this.sequenceSelected = undefined;
        this.noticeTextSelected = undefined;
        this.mediaSelected = undefined;
        this.dataService.sendData([Constant.IS_IN_PREVIEW_AREA, false]);
        this.changeDetectorRef.detectChanges();
        break;
    }
  }

  /**
   * confirmSaveBeforeLeave
   * @returns
   */
  private async confirmSaveBeforeLeave(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.dialogService.showDialog(
        DialogConfirmComponent,
        {
          data: {
            text: this.translateService.instant('announcement-manager.save-changes'),
            button1: this.translateService.instant('announcement-manager.yes'),
            button2: this.translateService.instant('announcement-manager.no')
          }
        },
        async data => {
          if (!data) {
            this.playlistSelected.isEdit = false;
            return resolve(true);
          }
          const isPass = await this.savePlaylistBeforeLeave();
          if (isPass) {
            return resolve(true);
          } else {
            return resolve(false);
          }
        }
      );
    });
  }

  /**
   * getNotificationMediaValidator
   */
  async getNotificationMediaValidator(): Promise<void> {
    try {
      this.notificationMediaValidator = await this.scheduleOperationManagerService.getNotificationMediaValidator().toPromise();
    } catch (error) {
      Helper.handleError(error, this.translateService, this.dialogService);
    }
  }

  /**
   * open date picker
   * @param picker
   * @param time
   */
  openDatePicker(picker: DatePickerDirective, time: any): void {
    picker.api.open();
    this.addPseudoSpan();
    picker.api.moveCalendarTo(time ?? moment());
  }

  /**
   * add element date picker
   */
  addPseudoSpan(): void {
    while (document.getElementById('span-new')) {
      document.getElementById('span-new').remove();
    }
    return;
  }

  /**
   * formatDate
   * @param date
   * @returns
   */
  formatDate(date: Date): string {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date
      .getDate()
      .toString()
      .padStart(2, '0');
    return `${year}-${month}-${day}`;
  }

  /**
   * handlingDynamicMessagingAPICalls
   * @returns
   */
  async handlingDynamicMessagingAPICalls(): Promise<void> {
    const devicesDelivery = [
      ...new Set(this.dynamicMessages?.filter(elememt => elememt.isChanged)?.map(dynamicMessage => dynamicMessage.device))
    ];
    if (!devicesDelivery || !devicesDelivery.length) {
      return;
    }
    const allPlaylistIds = new Set<number>();

    devicesDelivery.forEach(device => {
      const filteredMessages = this.dynamicMessages.filter(dynamicMessage => dynamicMessage.device.id === device.id);

      filteredMessages.forEach(dynamicMessage => {
        if (dynamicMessage.playlistTextId) allPlaylistIds.add(dynamicMessage.playlistTextId);
        if (dynamicMessage.playlistTextIdUpdate) allPlaylistIds.add(dynamicMessage.playlistTextIdUpdate);
        if (dynamicMessage.playlistMediaId) allPlaylistIds.add(dynamicMessage.playlistMediaId);
        if (dynamicMessage.playlistMediaIdUpdate) allPlaylistIds.add(dynamicMessage.playlistMediaIdUpdate);
      });
    });

    let playlistsResponse;

    try {
      playlistsResponse = await this.scheduleOperationManagerService.getListDetailNotificationSequence([...allPlaylistIds]).toPromise();
    } catch (error) {
      if (error.status == Constant.NETWORK_ERROR_CODE) {
        this.dialogService.showDialog(DialogMessageComponent, {
          data: {
            title: this.translateService.instant('dialog-message.error-title'),
            text: this.translateService.instant('dialog-error.error-network')
          }
        });
      } else if (error.error?.detail == Constant.PLAYLISTID_DOES_NOT_EXIST) {
        this.dialogService.showDialog(DialogMessageComponent, {
          data: {
            title: this.translateService.instant('dialog-message.error-title'),
            text: this.translateService.instant('dialog-message.error')
          }
        });
      }
      return;
    }

    const dynamicMessageList = await Promise.all(
      devicesDelivery.map(async device => {
        const filteredMessages = this.dynamicMessages.filter(dynamicMessage => dynamicMessage.device.id === device.id);

        const dynamic_message = filteredMessages
          .map(dynamicMessage => {
            const currentDate = this.getCurrentDateByTimeZone(false);
            let scheduleValue = [];

            if (dynamicMessage.textArea) {
              if (dynamicMessage.playlistTextId) {
                scheduleValue.push({
                  startDate: `${currentDate}${this.timezone}`,
                  playlist: this.getNamePlaylit(dynamicMessage.playlistTextId, playlistsResponse)
                });
              }
              if (dynamicMessage.playlistTextIdUpdate) {
                scheduleValue.push({
                  startDate: `${dynamicMessage.scheduleUpdate}${this.timezone}`,
                  playlist: this.getNamePlaylit(dynamicMessage.playlistTextIdUpdate, playlistsResponse)
                });
              }
              return {
                area: `${Constant.PREFIX_AREA}${dynamicMessage.textArea.id}`,
                type: Constant.TYPE_DYNAMIC_TEXT,
                schedule: scheduleValue
              };
            } else if (dynamicMessage.pictureArea) {
              if (dynamicMessage.playlistMediaId) {
                scheduleValue.push({
                  startDate: `${currentDate}${this.timezone}`,
                  playlist: this.getNamePlaylit(dynamicMessage.playlistMediaId, playlistsResponse)
                });
              }
              if (dynamicMessage.playlistMediaIdUpdate) {
                scheduleValue.push({
                  startDate: `${dynamicMessage.scheduleUpdate}${this.timezone}`,
                  playlist: this.getNamePlaylit(dynamicMessage.playlistMediaIdUpdate, playlistsResponse)
                });
              }
              return {
                area: `${Constant.PREFIX_AREA}${dynamicMessage.pictureArea.id}`,
                type: Constant.TYPE_DYNAMIC_IMAGE,
                schedule: scheduleValue
              };
            }
          })
          .filter(Boolean);

        const dynamic_message_playlist = {};

        const processedTextPlaylists = new Set<number>();
        const processedMediaPlaylists = new Set<number>();

        for (let indexFor = 0; indexFor < filteredMessages.length; indexFor++) {
          const dynamicMessage = filteredMessages[indexFor];

          // Lấy dữ liệu cho playlistTextId
          if (dynamicMessage.playlistTextId && !processedTextPlaylists.has(dynamicMessage.playlistTextId)) {
            dynamic_message_playlist[this.getNamePlaylit(dynamicMessage.playlistTextId, playlistsResponse)] = this.convertSequenceToPublish(
              dynamicMessage.playlistTextId,
              playlistsResponse
            );
            processedTextPlaylists.add(dynamicMessage.playlistTextId);
          }

          // Lấy dữ liệu cho playlistTextIdUpdate
          if (dynamicMessage.playlistTextIdUpdate && !processedTextPlaylists.has(dynamicMessage.playlistTextIdUpdate)) {
            dynamic_message_playlist[
              this.getNamePlaylit(dynamicMessage.playlistTextIdUpdate, playlistsResponse)
            ] = this.convertSequenceToPublish(dynamicMessage.playlistTextIdUpdate, playlistsResponse);
            processedTextPlaylists.add(dynamicMessage.playlistTextIdUpdate);
          }

          // Lấy dữ liệu cho playlistMediaId
          if (dynamicMessage.playlistMediaId && !processedMediaPlaylists.has(dynamicMessage.playlistMediaId)) {
            dynamic_message_playlist[
              this.getNamePlaylit(dynamicMessage.playlistMediaId, playlistsResponse)
            ] = this.convertSequenceToPublish(dynamicMessage.playlistMediaId, playlistsResponse);
            processedMediaPlaylists.add(dynamicMessage.playlistMediaId);
          }

          // Lấy dữ liệu cho playlistMediaIdUpdate
          if (dynamicMessage.playlistMediaIdUpdate && !processedMediaPlaylists.has(dynamicMessage.playlistMediaIdUpdate)) {
            dynamic_message_playlist[
              this.getNamePlaylit(dynamicMessage.playlistMediaIdUpdate, playlistsResponse)
            ] = this.convertSequenceToPublish(dynamicMessage.playlistMediaIdUpdate, playlistsResponse);
            processedMediaPlaylists.add(dynamicMessage.playlistMediaIdUpdate);
          }
        }

        return {
          device: device.registrationId,
          dynamic_message,
          dynamic_message_playlist
        };
      })
    );

    const payload = {
      dynamic_message_list: dynamicMessageList
    };
    this.apiCustomerService.deliveryDynamicMessage(payload).subscribe(
      async () => {
        this.executingService.executing();
        const devicesSave = await this.getDevicesSuccessForDelivery(devicesDelivery, true, payload);
        if (devicesSave.length > 0) {
          this.saveDynamicMessages(this.dynamicMessages, devicesSave, true);
        } else {
          this.showDialogSuccess('schedule-operation-manager.dynamic-message-on');
        }
      },
      error => this.handleErrorFromCustomerApi()
    );
  }

  /**
   * getNamePlaylit
   * @param idPlaylist
   * @param listPlaylist
   * @returns
   */
  getNamePlaylit(idPlaylist: number, listPlaylist: any[]): string {
    let index = listPlaylist?.findIndex(e => e?.id == idPlaylist);
    if (index != -1) {
      return listPlaylist[index]?.name;
    }
  }

  /**
   * getTimeToPublish with time axis greater than 24h
   */
  getTimeToPublish(startTime: string): string {
    const hourAndMinute = startTime.split(':').map(Number);
    const formattedHours = String(hourAndMinute[0] + 24).padStart(2, '0');
    const formattedMinutes = String(hourAndMinute[1]).padStart(2, '0');
    const formattedSeconds = '00';
    // Kết hợp giờ và phút theo định dạng HH:mm
    return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
  }

  private indexToChangeTime(): number {
    const hourMinute = this.timeSettingNotification.split(':').map(Number);
    const hourChange = hourMinute[0];
    const minuteChange = hourMinute[0] / 15;
    return 96 - hourChange * 4 - minuteChange;
  }

  getMediaOrTextValue(idMediaOrText: number, type: number): string {
    if (!idMediaOrText) {
      return null;
    }
    if (type == Constant.TEXT) {
      let index = this.listNoticeText.findIndex(e => e.id == idMediaOrText);
      if (!Helper.isEmpty(index) || index != -1) {
        return Helper.encodeHTML(this.listNoticeText[index]?.parsedDelta);
      }
    } else if (type == Constant.MEDIA) {
      let index = this.medias.findIndex(e => e.id == idMediaOrText);
      if (!Helper.isEmpty(index) || index != -1) {
        return this.medias[index]?.urlMediaDelivery;
      }
    }
  }

  /**
   * convertSequenceToPublish
   * @param playlistId
   * @param listPlaylist
   * @returns
   */
  convertSequenceToPublish(playlistId: number, listPlaylist: any): string {
    let sequenceOriginPublish = _.cloneDeep(Constant.SEQUENCE_ORIGIN);
    let indexElement = listPlaylist?.findIndex(e => e?.id == playlistId);
    if (indexElement == -1 || !this.timeSettingNotification) {
      return null;
    }
    if (listPlaylist[indexElement]?.sequence) {
      sequenceOriginPublish = JSON.parse(listPlaylist[indexElement].sequence);
      //reset sequenceOrigin from startTime = "00:00"
      sequenceOriginPublish = sequenceOriginPublish.sort((a, b) => {
        return this.convertTimeToMinutes(a.startTime) - this.convertTimeToMinutes(b.startTime);
      });
      const [hours] = this.timeSettingNotification.split(':').map(Number);
      sequenceOriginPublish.push(...sequenceOriginPublish.splice(0, hours * 4));

      const result = [];
      sequenceOriginPublish.forEach((sequence, index) => {
        if (
          index != 0 &&
          _.isEqual(
            sequenceOriginPublish[index - 1].value,
            sequence.value || (sequenceOriginPublish[index - 1].value == null && sequence.value)
          )
        ) {
          return;
        }
        const temp = {
          startTime: index < this.indexToChangeTime() ? `${sequence.startTime}:00` : this.getTimeToPublish(sequence.startTime),
          ...(listPlaylist[indexElement]?.group == 2
            ? { image: sequence.value ? this.getMediaOrTextValue(sequence.value, listPlaylist[indexElement]?.group) : null }
            : { message: sequence.value ? this.getMediaOrTextValue(sequence.value, listPlaylist[indexElement]?.group) : null })
        };
        result.push(temp);
      });
      return JSON.stringify(result);
    }
  }

  async savePlaylistBeforeLeave(): Promise<boolean> {
    return new Promise<boolean>(async (resolve, reject) => {
      try {
        if (this.validatePlaylistName()) {
          resolve(false);
          return;
        }

        const payload = {
          id: this.playlistSelected.id,
          name: this.playlistSelected.name,
          group: this.playlistSelected.group,
          sequence: JSON.stringify(
            this.sequenceOrigin.sort((a, b) => {
              return this.convertTimeToMinutes(a.startTime) - this.convertTimeToMinutes(b.startTime);
            })
          )
        };

        this.scheduleOperationManagerService.saveNotificationSequence(payload).subscribe(
          data => {
            if (data.error) {
              this.isSaving = false;
              this.dialogService.showDialog(DialogMessageComponent, {
                data: {
                  title: this.translateService.instant('announcement-manager.error'),
                  text: this.translateService.instant('announcement-manager.exists-pl')
                }
              });
              this.saveDataSuccess.emit(false);
              resolve(false);
            } else {
              this.saveDataSuccess.emit(true);
              resolve(true);
            }
          },
          error => {
            this.saveDataSuccess.emit(false);
            resolve(false);
          }
        );
      } catch (error) {
        console.error('Error saving playlist:', error);
        this.saveDataSuccess.emit(true);
        reject(false);
      }
    });
  }
}
/**
 * class DeviceOperation
 */
export class DeviceOperation {
  /**
   * id
   */
  id: Number;
  /**
   * registrationId
   */
  registrationId: string;
  /**
   * deviceId
   */
  deviceId: string;
  /**
   * network
   */
  network: boolean;
  /**
   * capture url
   */
  captureUrl: string;
  /**
   * true if on emergency
   */
  isOnEmergency: boolean;

  /**
   * customTag2
   */
  customTag2: any;

  constructor(id?: Number, registrationId?: string, deviceId?: string, network?: boolean, customTag2?: any) {
    this.id = id;
    this.registrationId = registrationId;
    this.deviceId = deviceId;
    this.network = network;
    this.customTag2 = customTag2;
  }
}

export class ScheduleDaily {
  id: number;
  rowDatas: Array<any>;
  isLessCurrentTime: boolean;

  constructor(id: number, rowDatas: Array<any>) {
    this.id = id;
    this.rowDatas = rowDatas;
  }
}
